import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate,Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { CSVLink, CSVDownload } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';
import Navbar from './Navbar';




const UploadLead = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    const csvData = [
        ["Number","Name","Email"]
      ];

    const el = useRef();

    const [uploadFile, setUploadFile] = useState();
    const [listname, setListname] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const uploadaudioonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('file', uploadFile)
        //formData.append('listname', listname)

        formData.append('username', username)
        

        const response = await fetch(process.env.REACT_APP_IP+"/Admin/UploadNumber", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        props.showAlert(" Uploaded Successfully "+ json.msg, "success");
        getAllPosts()

        el.current.value = "";



        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }



    const downloadsample = async (e) => {
        e.preventDefault();
      
        const response = await fetch(process.env.REACT_APP_IP+"/User/api/foo.csv", {
            method: 'GET',
           headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        });
        const json = await response.json()
       // console.log(json)
        props.showAlert(" Downloaded Successfully ","success");
        getAllPosts()    
    }

   





    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/AllLeadsdata?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])

    const [username,setUsername] = useState("ALL");

    const optionforusername = async (e) => {
      const respp = await fetch(process.env.REACT_APP_IP+"/Admin/RestAllUserdrop", {
          method: 'POST',
          // headers: {
          //     'Content-Type': 'application/json'
          // },
    
          headers: {
              'Content-Type': 'application/json',
    
              "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
    
          // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      })
      const json = await respp.json()
      console.log(json.length)
    
      var elelist = document.getElementById('usernameiddropdown');
      
      
      for (var i = 0; i < json.length; i++) {
          // POPULATE SELECT ELEMENT WITH JSON.
          elelist.innerHTML = elelist.innerHTML +
              '<option  value="' + json[i]['username'] + '">' + json[i]['id'] +' | ' + json[i]['username'] + '</option>';
      }
    
    
      
    
    
    }
    
    useEffect(() => {
        optionforusername()
    }, [])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>

<div className='row mt-1 '>

{/* <AddCampaignform /> */}





< div className='col-md-12 col-12' >

    <div className='card  mt-5' style={{
        backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)"
        
    }}>

        <div className='card-header text-white'>
            <h2>Existing Lead</h2>

           
            

            <div className="table-responsive mt-3">

                <table className="table table-hover table-bordered">
                    <thead className="bg-darkblue text-white">
                        <tr>
                            <th>ID</th>
                            {localStorage.getItem('roles') === 'ROLE_ADMIN'?<th>User Name</th>:''}
                            <th>Name</th>
                            <th>Number</th>
                            <th>CallLog</th>
                            <th>Date</th>

                            <th>Email</th>
                            <th>Remarks</th>
                            <th>Edit</th>
                            <th>Status</th>
                            <th>Interested</th>
                           
                            {/* <th></th> */}


                        </tr>
                    </thead>
                    <tbody>
                        {data.content.map(page => {
                            return (
                                <tr key={page.id}>
                                    <td>{page.id}</td>
                                    {localStorage.getItem('roles') === 'ROLE_ADMIN'?<td>{page.username}</td>:''}
                                    <td>{page.custname}</td>
                                    <td>{page.custnumber}</td>
                                    
                                    {/* <td><i className="fa fa-info-circle  btn btn-primary " onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                                   <td> <Link to="/LeadCalllog" state={ {id: page.id}}>
                                        <i className="fa fa-info-circle btn btn-primary " aria-hidden="true" ></i>
                                        
                                        {/* <LeadCalllog data={page.id} /> */}
                                        </Link></td>

                                    <td>{page.leadinsertdate}</td>

                                    <td>{page.email}</td>
                                    
                                    <td> {page.remarks}</td>
                                    <td>
                                    
                                        </td>
                                    <td> {page.status}</td>
                                    <td> {page.interested}</td>
                                    
                                        
                                        {/* <i className="fa fa-edit btn btn-primary "
                                         style={{marginLeft:"15px"}} onClick={() => updateRemarks(page.id)} >
                                             </i>
                                             
                                             </td> */}
                                    {/* <td>
                                       <button className="btn btn-primary" 
                                            onClick={fireAlert}>
                                            Click me
                                        </button>
                                     </td> */}



                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                    placeholder="gotopage" onChange={onInputChange} />

            </div>
        </div>
    </div>


</div>

</div>


            </>

        )

    }


    return (


      <>
      <Navbar />
      

        <div className='row mt-4 mx-1'>




            {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
            <div className='col-md-3 col-12 text-white'>


                <form className='card p-3' style={{
                    backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)"
                    , minHeight: "400px", marginTop: "50px"
                }} onSubmit={uploadaudioonsubmit}>
                    <h4>Upload Number</h4>


                    {/* <div className="mb-3 mt-3">
                        <label htmlFor="listname">List Name</label>
                        <input name="listname" type="text" className=" form-control form-control-sm"
                            onChange={(e) => setListname(e.target.value)} required />

                        
                    </div> */}

                    <div className=" mb-2 mt-3">
                          <label htmlFor="user" className="form-label">User Name</label>
                         <select id="usernameiddropdown" className="form-select" name="username"  onChange={(e) => setUsername(e.target.value)} required>
                            <option value="ALL">-- Select User --</option>
                            <option value="ALL">ALL</option>

                        </select>
                       
                    </div>

                    <div className="mb-3 mt-3">
                        

                        <input className=" form-control form-control-sm" ref={el}
                            id="formFileSm" name="file" type="file" onChange={onchangfun} required />
                    </div>
                 <div className='row'>
                     <div className='col-md-6'>
                            {/* <i className="fa-solid fa-download fa-2x btn btn-success  mt-3 " onClick={downloadsample} ></i> */}



                          
 <CSVLink className="btn btn-success mt-3" data={csvData} filename={"SampleFile.csv"} target="_blank" ><i className="fa-solid fa-download"></i> CSV Format</CSVLink>
   {/* or  */}
{/* <CSVDownload data={csvData} target="_blank" />; */}
                     </div>
                     <div className='col-md-6'>
                             <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                    </div>

                 </div>
                    

                    
                </form>

            </div>


            <div className="col-md-9 col-12">

                {/* Display all the posts */}
                {posts}

                <div className="row">
                    <div className="col-md-3 col-12"></div>

                    <ReactPaginate
                            previousLabel={<i className="fa-solid fa-angles-left"></i>}
                            nextLabel={<i className="fa-solid fa-angles-right"></i>}
                            breakLabel={".."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />

                </div>



            </div>




        </div>

        </>

    )


}


export default UploadLead
