

import '../App.css';
import { useEffect, useState } from 'react'
import { useNavigate, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import Login from './Login';
import Navbar from './Navbar';
import { CSVLink } from 'react-csv';

export const Home = (props) => {

    let history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/login');
        }
    }, [])

    useEffect(() => {
        document.title = "Home"
    }, []);


   
  
    

    const[totalcamp,settotalcamp] = useState(0)
    const totalcampfun = async () => {  
        const res = await axios.get(process.env.REACT_APP_IP+`/User/Counttotalcampaign`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalcamp(data)
    
      }

      useEffect(() => {
        totalcampfun()
    }, [])


    const[activeagent,setactiveagent] = useState(0)
    const activeagentfun = async () => {  
        const res = await axios.get(process.env.REACT_APP_IP+`/User/CountActiveobdcallpatchagent`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        setactiveagent(data)
    
      }

      useEffect(() => {
        activeagentfun()
    }, [])

    const [postsPerPage] = useState(10);
    const [postsPerPage2] = useState(10);
    const [offset, setOffset] = useState(1);
    const [offset2, setOffset2] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [posts2, setAllPosts2] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [pageCount2, setPageCount2] = useState(0)


    // const getAllPosts = async () => {
    //     const res = await axios.get(`http://localhost:8080/withouttokenhello`)
    //     const data = res.data;
    //     const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    //     // For displaying Data
    //     const postData = getPostData(slice)

    //     // Using Hooks to set value
    //     setAllPosts(postData)
    //     setPageCount(Math.ceil(data.length / postsPerPage))
    // }

    
   

    const headers = [
        { label: "Caller", key: "CustomerNumber" },
        { label: "Source", key: "did" },
        { label: "Calltype", key: "type" },
        { label: "Agents Name", key: "agentname" },
        { label: "Agents number", key: "AgentNumber" },
        { label: "Start", key: "dialtime" },
        { label: "End", key: "agenthanguptime" },
        { label: "Duration", key: "totalduration" },
        { label: "Keypress", key: "pressedkey" },
        { label: "Dial Status", key: "dialstatus" }
    
    
      ];

    const[keypresseddata,setKeypresseddata] = useState([]);

    const getdata = async () => {
        const res = await axios.get(process.env.REACT_APP_IP + `/User/getOnlyKeypressed`,
          { 
            headers: 
            { 
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem('token')}` 
            } 
          }
          )
    
          // console.log(">>>>>>>>>>>rec>>>>>>>>"+process.env.REACT_APP_REC_LINK)
          // console.log(">>>>>>>>>>>rec>>>>>>>>"+process.env.REACT_APP_IP)
          
    
        //const datakk = res.data;
    
       // const json = await res.data
    
        //alert(JSON.stringify(res.data))
    
        //return JSON.stringify(res.data);
        setKeypresseddata(res.data)
    
    
      }
      
      //const data = reportdaat;
    
      useEffect(() => {
        getdata();
      }, [])

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP + `/User/AllReport?pageNumber=${offset - 1}&&
        size=10`,
            { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

        const data = res.data;

        const postData = getPostData(data)

        setAllPosts(postData)

        setPageCount(Math.ceil(data.totalPages))
    }


    const getAllPosts2 = async () => {
        const res2 = await axios.get(process.env.REACT_APP_IP + `/User/getRecord`,
            { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data2 = res2.data;
        const slice2 = data2.slice(offset2 - 1, offset2 - 1 + postsPerPage2)

        // For displaying Data
        const postData2 = getPostData2(slice2)

        //     // Using Hooks to set value
        setAllPosts2(postData2)
        setPageCount2(Math.ceil(data2.length / postsPerPage2))
    }


    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handlePageClick2 = (event) => {
        const selectedPage2 = event.selected;
        setOffset2(selectedPage2 + 1)
    };

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getAllPosts()
        }
    }, [offset])

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getAllPosts2()

        }

    }, [offset2])


    const { showAlert } = props

    const getPostData2 = (data2) => {

        return (
            <>



                {/* <h2>This is home page</h2> */}

                {/* call */}


                <div className="col-sm-12 col-12 my-2">
                    <h5>Pending Data List</h5>
                    <div className="table-responsive">
                        <table className="table table-hover bg-darkblue text-white table-striped ">
                            <thead>
                                <tr>
                                    <th>ListName</th>
                                    <th>Pendingdata</th>
                                    <th>Dialeddata</th>
                                    <th>Totaldata</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data2.map(page2 => {
                                    return (
                                        <tr key={page2.id}>
                                            <td>{page2.list}</td>
                                            <td>{page2.pendingdata}</td>
                                            <td>{page2.dialeddata}</td>
                                            <td>{page2.totaldatainlist}</td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>



            </>




        )
    }
    const getPostData = (data) => {

        return (
            <>




                {/* <h2>This is home page</h2> */}

                {/* call */}
                <div className="row " >



                    <div className="col-sm-12 col-12 my-2" >
                        <h5>Last Call Report</h5>
                        <div className="table-responsive">
                            <table className="table table-hover  table-striped  bg-darkblue text-white">
                                <thead>
                                    <tr>

                                        <th>CustomerNumber</th>
                                        <th>Campaign</th>
                                        <th>Dialstatus</th>
                                        <th>keypress</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.content.map(page => {
                                        return (
                                            <tr key={page.id}>

                                                <td>{page.CustomerNumber}</td>
                                                <td>{page.campaign}</td>
                                                <td>{page.dialstatus}</td>
                                                <td>{page.pressedkey}</td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </>




        )
    }


    return (



        <div>
            <Navbar />


            {
                localStorage.getItem('token') ?

                    <div className="container-fluid mt-5 p-5">


                        {/* <h1>This is Home page</h1> */}

                        <div className="row">

                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box mybg-primary">
                                    <div className="inner">
                                        <h3>{activeagent}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-user"></i>
                                        </div>

                                        <p>Active Agent</p>

                                    </div>
                                      
                                    <Link className="small-box-footer" to="/AddAgent" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link>
                               
                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                    

                                </div>
                            </div>

                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{totalcamp}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-sitemap"></i>
                                            {/* <i class="fa-solid fa-sitemap"></i> */}
                                        </div>

                                        <p>Total Campaign</p>

                                    </div>

                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                    
                                    
                                    <Link className="small-box-footer" to="/AddCampaign" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link>
                               
                               
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box mybg-info">
                                    <div className="inner">
                                        <h3>1</h3>
                                        <div className="icon">
                                            {/* <i className=" fa fa-volume-control-phone"></i> */}
                                            <i className=" fa fa-sitemap"></i>
                                        </div>

                                        <p>Today Total Campaign</p>

                                    </div>

                                     
                                    <Link className="small-box-footer" to="/AddCampaign" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link>
                               


                                </div>
                            </div>



                            <div className="col-lg-3 col-6 text-white">

                                    <div className="small-box mybg-warning">
                                        <div className="inner">
                                            <h3>1</h3>
                                            <div className="icon">
                                                {/* <i className=" fa fa-volume-control-phone"></i> */}
                                                <i className=" fa fa-sitemap"></i>
                                            </div>

                                            <p>Total Dialed Number</p>

                                        </div>

                                        
                                        <Link className="small-box-footer" to="/Report" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link>



                                    </div>
                           </div>


                            

                           

                                <div className="col-lg-3 col-6 text-white">

                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>{keypresseddata.length}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-file-text-o"></i>
                                            {/* <i class="fa-solid fa-indian-rupee-sign"></i> */}
                                        </div>

                                        <p>KeyPressed Data</p>

                                    </div>
                                    
              <CSVLink className="small-box-footer bg-success" data={keypresseddata} headers={headers} filename={"Callreport.csv"} target="_blank"  ><i className="fa fa-download fa-lg mx-1"></i> Download Report</CSVLink>
          

        {/* <a href="#" className="small-box-footer">Download  <i className="fas fa-download"></i></a> */}
                                </div>
                                </div>


                               
                            



                        </div>
                        {/* Display all the posts */}
                        <div className="row" style={{ marginLeft: "-5px", marginRight: "-5px" }}>
                            <div className="col-sm-8 col-12 my-2" >
                                {posts}

                                <div className="row">
                                    <div className="col-sm-2" ></div>
                                    <div className="col-sm-6" >

                                        {/* Using React Paginate */}
                                        <ReactPaginate
                                            previousLabel={"previous"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                    </div>
                                    <div className="col-sm-2" ></div>
                                </div>

                            </div>
                            <div className="col-sm-4 col-12 my-2" >
                                {posts2}



                                {/* Using React Paginate */}

                                <div className="row">
                                    <div className="col-sm-2" ></div>
                                    <div className="col-sm-8" >
                                        <ReactPaginate
                                            previousLabel={"previous"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount2}
                                            onPageChange={handlePageClick2}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination2"}
                                            activeClassName={"active"} />
                                    </div>

                                    <div className="col-sm-2" ></div>

                                </div>


                            </div>
                        </div>


                    </div> : <div>

                        <Login />
                    </div>

            }

        </div>





    )



}

export default Home
