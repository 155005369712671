import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-date-picker';
import axios from 'axios'
import dateFormat from "dateformat";
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import ReactAudioPlayer from 'react-audio-player';


const FilterReport = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])


  useEffect(() => {
    document.title = "Filter Report"
 }, []);


  const [startdate, setstartdateval] = useState(new Date());

  const [enddate, setEnddateval] = useState(new Date());

  const [sddate, setsddate] = useState("NA");
  const [eddate, seteddate] = useState("NA");


  const [campaignname,setCampaignname] = useState("0");

  const [keypress,setKeypress] = useState("1");

  const [opennav, setopennav] = useState();
  const[listname,setList] = useState();


  const setstartdate = (event) => {
      //alert(event)
    setstartdateval(event)
    var sttt=dateFormat(event, "yyyy-mm-dd")

 

  setsddate(sttt)
  


}

const optionforlist = async (e) => {
  const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllcampname", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
          'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
  })
  const json = await respp.json()
  console.log(json.length)

  var elelist = document.getElementById('campnameid');
  for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
          '<option  value="' + json[i]['id'] + '">' + json[i]['campname'] + '</option>';
  }

 

  


}

useEffect(() => {
  optionforlist()
}, [])

const setenddate = (event) => {
    setEnddateval(event)
    var enddd=dateFormat(event, "yyyy-mm-dd")
    seteddate(enddd)

}

  const toggleClass = () => {
    //alert("clicked");
    if (!opennav) {
      setopennav(true);
    } else {
      setopennav(false);
    }
    // alert("clicked"+opennav);

  }

  const filterreportsubmit =  async (event) => {
    event.preventDefault();

    //const DATE_FORMATER = require( 'dateformat' );
    //var datetimef = DATE_FORMATER( startdate, "yyyy-mm-dd" );
    

  console.log(">>>>startdate>>>>>>>"+dateFormat(startdate, "yyyy-mm-dd")+">>>>>enddate>>>>>>>"
  +dateFormat(enddate, "yyyy-mm-dd")+">>>>>>campaignname>>>>>>"+campaignname+">>>>>>keypress>>>>>>"+keypress);
  

  getAllPosts()




  }

  const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>

<tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id}>
                      <td>{page.id}</td>
                      <td>{page.CustomerNumber}</td>
                      <td>{page.AgentNumber}</td>
                      <td>{page.campaignid} | {page.campaign}</td>

                      <td>
                          <ReactAudioPlayer 
                                    src={process.env.REACT_APP_REC_OBD+'obd/'+page.fold+'/'+page.recname}
                              controls />
                      </td>
                      <td>{page.dialtime}</td>
                      <td>{page.customeranswertime}</td>
                      <td>{page.customerhanguptime}</td>
                      <td>{page.duration}</td>
                      <td>{page.totalduration}</td>
                      <td>{page.pressedkey}</td>

                      

                    </tr>
                  );
                })}
              </tbody>
        
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/checkreport?pageNumber=${offset - 1}&&
    size=10&&startdate=${sddate}&&enddate=${eddate}&&keypress=${keypress}&&campid=${campaignname}`,
     { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts()
  }, [offset])



  return (

    <>
    <Navbar />
   
    <div className='row mt-5 p-3 mybgcolor'>





      <div className={!opennav ? "col-md-12 col-12  my-2" : "col-md-12 col-12  p-3 my-2"}>



        <div className='row'>
        <div className='col-md-3'>
             <div className='card p-2 mt-2'>
                 <form onSubmit={filterreportsubmit} >
                     <h4>Filter Report</h4>
                     {/* <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Campaign Name</label>
                        <select className="form-select" name="campaignname" 
                            onChange={(e) => setCampaignname(e.target.value)} >
                            <option value="All">ALL</option>
                           
                        </select>

                    </div> */}
                    <div className=" mb-2 mt-2">
                          <label htmlFor="campaignname" className="form-label">Campaign Name</label>
                         <select id="campnameid" className="form-select" name="campaignname"  onChange={(e) => setCampaignname(e.target.value)} required>
                            <option value="">-- Select Campaign --</option>
                            <option value="0">ALL</option>

                        </select>
                       
                    </div>

                    <div className="mb-3 mt-2">
                        <label  className="form-label">KeyPress</label>
                        <select className="form-select" name="keypress" value={keypress}
                            onChange={(e) => setKeypress(e.target.value)} >
                            {/* <option value="All">ALL</option> */}
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                           
                        </select>

                    </div>

                    <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Start Date</label>
                       
                        <DatePicker className="form-control" onChange={setstartdate} value={startdate} />

                    </div>

                    <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">End Date</label>
                       
                        <DatePicker className="form-control" onChange={setenddate} value={enddate} />

                    </div>

                    <div className="d-grid gap-2 mb-5 mt-2">
                         <input className="btn btn-primary " type="submit" value="Submit" />
                    </div>
                    </form>

             </div>
             
                     
                     
                   

             



        </div>
        
        <div className='col-md-9'>
                <div className="table-responsive">
                <h1>Filter Report</h1>
                {/* Display all the posts */}
                <div className='mybgcolor'>
       
          <div className="table-responsive">
            <table className="table table-hover table-bordered">
            <thead className="bg-darkblue text-white">
                <tr>
                  <th>ID</th>
                  <th>Customer Number</th>
                  <th>Agent Number</th>
                  <th>Campaign</th>
                  <th></th>
                  <th>Dialtime</th>
                  <th>AnswerTime</th>
                  <th>HangTime</th>
                  <th>Duration</th>
                  <th>TotalDuration</th>
                  <th>PressedKey</th>

                </tr>
              </thead>
             

              {posts}
            </table>

            Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
              placeholder="gotopage" onChange={onInputChange} />
            {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
          </div>
          
        </div>
               

                {/* Using React Paginate */}
               
                </div>

                
                <div className='row mb-5'>
                <div className='col-md-3'></div>
<div className='col-md-6'>
<ReactPaginate
    previousLabel={"previous"}
    nextLabel={"next"}
    breakLabel={".."}
    breakClassName={"break-me"}
    breakLinkClassName={"break-mef"}
    pageCount={pageCount}
    onPageChange={handlePageClick}
    forcePage={offset - 1}

    containerClassName={"pagination"}
    subContainerClassName={"pages pagination"}
    activeClassName={"active"} />

</div>
</div>


        </div>
        

</div>
      </div>




    </div>
    </>
  )
}

export default FilterReport
