import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-date-picker';
import axios from 'axios'
import dateFormat from "dateformat";
import { motion } from 'framer-motion';
import Userivrnavbar from './Userivrnavbar';
import ReactAudioPlayer from 'react-audio-player';
import { CSVLink, CSVDownload } from "react-csv";


const IvrfilterReport = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])


  useEffect(() => {
    document.title = "Filter Report"
 }, []);






 const headers = [
  { label: "Caller", key: "CustomerNumber" },
  { label: "Source", key: "did" },
  { label: "Calltype", key: "type" },
  { label: "Agents Name", key: "agentname" },
  { label: "Agents number", key: "AgentNumber" },
  { label: "Start", key: "dialtime" },
  { label: "End", key: "agenthanguptime" },
  { label: "Duration", key: "totalduration" },
  { label: "Keypress", key: "pressedkey" },
  { label: "Dial Status", key: "dialstatus" }


];



//const data = reportdaat;

// useEffect(() => {
//   getdata();
// }, [])

const [reportdaat, setreportdaat] = useState([]);
const [todayreport, settodayreport] = useState([]);
const [todaymissreport, settodaymissreport] = useState([]);



  const [startdate, setstartdateval] = useState(new Date());

  const [enddate, setEnddateval] = useState(new Date());

  const [sddate, setsddate] = useState("NA");
  const [eddate, seteddate] = useState("NA");


  const [did,setdid] = useState("ALL");

  const [keypress,setKeypress] = useState("ALL");

  const [opennav, setopennav] = useState();
  const[listname,setList] = useState();


  const setstartdate = (event) => {
      //alert(event)
    setstartdateval(event)
    var sttt=dateFormat(event, "yyyy-mm-dd")

 

  setsddate(sttt)
  


}

const gettodaymisseddata = async (e) => {
  
   
  const res = await fetch(process.env.REACT_APP_IP+"/User/TodayreportMissedcalldownload", {
    method: 'POST',
    // headers: {
    //     'Content-Type': 'application/json'
    // },

    headers: {
        // 'Content-Type': 'multipart/form-data',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
   // body: formData

    // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
});
const json = await res.json()

settodaymissreport(json)


}


const gettodaydata = async (e) => {
  
   
   const res = await fetch(process.env.REACT_APP_IP+"/User/Todayreportdownload", {
     method: 'POST',
     // headers: {
     //     'Content-Type': 'application/json'
     // },

     headers: {
         // 'Content-Type': 'multipart/form-data',

         "Authorization": `Bearer ${localStorage.getItem('token')}`
     }
    // body: formData

     // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
 });
 const json = await res.json()

settodayreport(json)


}

const getdata = async (e) => {
  // e.preventDefault();
   const formData = new FormData()
   formData.append('did', did)
   formData.append('keypress', keypress)
   formData.append('startdate', sddate)
   formData.append('enddate', eddate)


   //alert("eddate>>>>>>>>>>>>>>"+eddate)
   
   const res = await fetch(process.env.REACT_APP_IP+"/User/AllfilterReportdownload", {
     method: 'POST',
     // headers: {
     //     'Content-Type': 'application/json'
     // },

     headers: {
         // 'Content-Type': 'multipart/form-data',

         "Authorization": `Bearer ${localStorage.getItem('token')}`
     },
     body: formData

     // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
 });
 const json = await res.json()

setreportdaat(json)


}

const optionforlist = async (e) => {
  const respp = await fetch(process.env.REACT_APP_IP+"/User/allDidbyuserid", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
          'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
  })
  const json = await respp.json()
  console.log(json.length)

  var elelist = document.getElementById('campnameid');
  for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
          '<option  value="' + json[i]['did'] + '">' + json[i]['did'] + '</option>';
  }

 

  


}

useEffect(() => {
  optionforlist()
}, [])

useEffect(() => {
  gettodaydata()
}, [])


useEffect(() => {
  gettodaymisseddata()
}, [])



const setenddate = (event) => {
    setEnddateval(event)
    var enddd=dateFormat(event, "yyyy-mm-dd")
    seteddate(enddd)

}

  const toggleClass = () => {
    //alert("clicked");
    if (!opennav) {
      setopennav(true);
    } else {
      setopennav(false);
    }
    // alert("clicked"+opennav);

  }

  const filterreportsubmit =  async (event) => {
    event.preventDefault();
  console.log(">>>>startdate>>>>>>>"+dateFormat(startdate, "yyyy-mm-dd")+">>>>>enddate>>>>>>>"
  +dateFormat(enddate, "yyyy-mm-dd")+">>>>>>did>>>>>>"+did+">>>>>>keypress>>>>>>"+keypress);
  

  getAllPosts()

  getdata()




  }

  const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
      <tbody>
                {data.content.map((page,index) => {
                  return (
                    <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                     <td>{((offset -1)  * 10) + index + 1}</td>
                      <td>{page.CustomerNumber}</td>
                      <td>{page.did}</td>
                      <td>{page.type}</td>
                      <td>{page.agentname}  {page.AgentNumber}</td>
                      <td >{page.dialtime}</td>
                      <td>{page.agenthanguptime}</td>
                      <td>{page.totalduration}</td>
                      <td>{page.pressedkey}</td>
                      <td>{page.dialstatus}</td>
                      {/* <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                     
                      <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}}  ></i></td> 
                      
                      <td>
                          <ReactAudioPlayer 
                                    src={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname}
                              controls />
                      </td>

                      <td></td>
                      <td></td>
                      <td><i className="fa fa-comment-o fa-xl btn btn-success " style={{marginLeft:"15px"}}  ></i></td>
                      <td><i className="fa fa-arrow-circle-o-up fa-2xl btn btn-info" style={{marginLeft:"15px"}}  ></i></td>


                    </tr>
                  );
                })}
              </tbody>
      
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);
    //alert("eddate>>>>>>>>>>>>>>"+eddate)

    const res = await axios.get(process.env.REACT_APP_IP+`/User/IvrReportFilter?pageNumber=${offset - 1}&&
    size=10&&startdate=${sddate}&&enddate=${eddate}&&keypress=${keypress}&&did=${did}`,
     { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts()
  }, [offset])



  return (
    <>
     <Userivrnavbar />
    
   
    <div className='row mt-5 p-3'>





      <div className={!opennav ? "col-md-12 col-12  my-2" : "col-md-12 col-12  p-3 my-2"}>



        <div className='row mt-2 mybgcolor'>
        <div className='col-md-3'>
             <div className='card p-2 '>
                 <form onSubmit={filterreportsubmit} >
                     <h4 className='card-header text-center'>Filter Report</h4>
                     {/* <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Campaign Name</label>
                        <select className="form-select" name="campaignname" 
                            onChange={(e) => setCampaignname(e.target.value)} >
                            <option value="All">ALL</option>
                           
                        </select>

                    </div> */}
                    <div className=" mb-2 mt-2">
                          <label htmlFor="campaignname" className="form-label">Source</label>
                         <select id="campnameid" className="form-select" name="campaignname"  onChange={(e) => setdid(e.target.value)} required>
                            <option value="">-- Select Source --</option>
                            <option value="ALL">ALL</option>

                        </select>
                       
                    </div>

                    <div className="mb-3 mt-2">
                        <label  className="form-label">KeyPress</label>
                        <select className="form-select" name="keypress" value={keypress}
                            onChange={(e) => setKeypress(e.target.value)} >
                            <option value="All">ALL</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                           
                        </select>

                    </div>

                    <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Start Date</label>
                       
                        <DatePicker className="form-control" onChange={setstartdate} value={startdate} />

                    </div>

                    <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">End Date</label>
                       
                        <DatePicker className="form-control" onChange={setenddate} value={enddate} />

                    </div>

                    <div className="d-grid gap-2 mb-5 mt-2">
                         <input className="btn btn-primary " type="submit" value="Submit" />
                    </div>
                    </form>

             </div>
             
                     
                     
                   

             



        </div>
        
        <div className='col-md-9'>
                <div className="table-responsive">
                <h4 className='text-center card-header'>Filter Report</h4>
                
                 <CSVLink className="btn btn-primary mt-3  mb-1 p-2" data={reportdaat} headers={headers} filename={"Callreport.csv"} target="_blank"  >
                         <i className="fa-solid fa-download  mx-2"></i>Filter Report
                </CSVLink>

                <CSVLink className="btn btn-primary mt-3 mx-2 mb-1 p-2" data={todayreport} headers={headers} filename={"Todayreport.csv"} target="_blank"  >
                         <i className="fa-solid fa-download mx-2"></i>Today Report
                </CSVLink>

                <CSVLink className="btn btn-primary mt-3 mb-1 p-2" data={todaymissreport} headers={headers} filename={"Callreport.csv"} target="_blank"  >
                         <i className="fa-solid fa-download mx-2"></i>Today MissedCall
                </CSVLink>

                {/* <CSVLink className="btn btn-primary mt-3 mx-2 mb-1 p-2" data={reportdaat} headers={headers} filename={"Callreport.csv"} target="_blank"  >
                         <i className="fa-solid fa-download mx-2"></i>Today Unique MissedCall
                </CSVLink>

                <CSVLink className="btn btn-primary mt-3 mx-2 mb-1 p-2" data={todayreport} headers={headers} filename={"Callreport.csv"} target="_blank"  >
                         <i className="fa-solid fa-download mx-2"></i>Today Unique Report
                </CSVLink> */}
          
                {/* Display all the posts */}
                <div className='mybgcolor'>
       
          <div className="table-responsive">

          <table className="table table-hover table-bordered mytablecss">
              <thead className="bg-darkblue text-white">
                <tr>
                  <th>ID</th>

                  <th>Caller</th>
                  <th>Source</th>
                  <th>Calltype</th>
                  <th>Agents</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Duration</th>
                  <th>Keypress</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Recording</th>
                  <th>Circle</th>
                  <th>Operator</th>
                  <th>Remarks</th>
                  <th>SMS Suite</th>
                  

                </tr>
              </thead>
              
              {posts}
            </table>

            Goto Pageno:<input type="text" className="col-md-1 mx-2 mb-2" id="gotopage" aria-describedby="gotopage"
              placeholder="gotopage" onChange={onInputChange} />

            </div>
            
          
        </div>
               

                {/* Using React Paginate */}
               
                </div>

                
                <div className='row mb-5'>
                <div className='col-md-3'></div>
<div className='col-md-6'>
<ReactPaginate
   previousLabel={<i className="fa-solid fa-angles-left text-black"></i>}
   nextLabel={<i className="fa-solid fa-angles-right text-black"></i>}
    breakLabel={".."}
    breakClassName={"break-me"}
    breakLinkClassName={"break-mef"}
    pageCount={pageCount}
    onPageChange={handlePageClick}
    forcePage={offset - 1}

    containerClassName={"pagination"}
    subContainerClassName={"pages pagination"}
    activeClassName={"active"} />

</div>
</div>


        </div>
        

</div>
      </div>




    </div>
    </>
  )
}

export default IvrfilterReport
