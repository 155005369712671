import React from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";

const Error = () => {
  // alert("ok");
  return (
    <> 
    <div className='text-center mt-80'>
    <h3>
      {/* <FontAwesomeIcon icon={faExclamationTriangle} className='text-warning' /> */}
      <span> Oops! Page not found.</span>
    </h3>
    <p>
      <span>We could not find the page you were looking for. Meanwhile, you may </span><br></br>


      <Link className='btn btn-primary' to='/'>Back Home</Link>
      
    </p>

   
  </div>



    </>
  )
}

export default Error