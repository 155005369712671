import React from 'react'
const About = () => { 

    
    return (
        <div className="mybgback mt-5 p-3">
                 
               
                   This is About page
               
                
                
        </div>
    )
}

export default About
