import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';


import axios from 'axios'

import ReactAudioPlayer from 'react-audio-player';
import { AnimatePresence, motion } from "framer-motion";
import Navbar from './Navbar';


const Report = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])

  useEffect(() => {
    document.title = "Report"
 }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 1
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 1.2
    }
  };
  
  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 1.0
  };
  
  const pageStyle = {
    // position: "absolute"
  };
 
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
    
    <tbody>
             {data.content.map(page => {
               return (
                 <tr key={page.id}>
                   <td>{page.id}</td>
                   <td>{page.campaignid}</td>
                   <td>{page.dialtime}</td>
                   <td>{page.customeranswertime}</td>
                   <td>{page.customerhanguptime}</td>
                   <td>{page.duration}</td>
                   <td>{page.totalduration}</td>
                   <td>{page.pressedkey}</td>
                   <td>
                       <ReactAudioPlayer 
                           src="https://www.computerhope.com/jargon/m/example.mp3"
                           controls />
                   </td>
                 </tr>
               );
             })}
           </tbody>
       
       
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllReport?pageNumber=${offset - 1}&&
    size=10`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts();
  }, [])



  return (
  
    <div className='row mt-5 p-3'>
         <Navbar />
        <div className='row'>
          <div className='col-md-12'>
            <div className="table-responsive">
              <h1 className='text-center mt-2 mb-2'>Report</h1>
              {/* Display all the posts */}

              <div className='sectiona'>
       
       <div className="table-responsive">
       <motion.div
       // style={pageStyle}
       // initial={{ x: 300, opacity: 0 }}
       // animate={{ x: 0, opacity: 1,rotate: 360}}
       // exit={{ rotate: 360}}
       // variants={pageVariants}
       // transition={pageTransition}
       // initial={{ scale: 0 }}
       // // animate={{ scale: 1 }}
       // animate={{
       //   scale: [1, 2, 2, 1, 1],
       //   rotate: [0, 0, 270, 270, 0],
       //   borderRadius: ["20%", "20%", "50%", "50%", "20%"],
       // }}
       // transition={{ duration: 1.5 }}
      
      
         //animate={{ rotate: [0,1,1, 0] }}
                  // transition={{ type: "spring", duration: 5, bounce: 0.6 }}
                  initial={{ x: -1200 }}
      
                  animate={{ x: 0 }}
                  transition={{ duration: 0.5}}
                 // transition={{ type: "spring",bounce: 0.55,duration: 1.1}}
      
                 // transition={{ type: "spring", duration: 5, bounce: 0.6 }}
      
      >
         <table className="table table-hover table-bordered">
           <thead className="bg-darkblue text-white">
             <tr>
               <th>ID</th>

               <th>Campaign</th>
               <th>Dialtime</th>
               <th>AnswerTime</th>
               <th>HangTime</th>
               <th>Duration</th>
               <th>TotalDuration</th>
               <th>PressedKey</th>
               <th>Audio</th>

             </tr>
           </thead>
           
           {posts}
         </table>

         Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
           placeholder="gotopage" onChange={onInputChange} />

</motion.div>
         {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
       </div>
      
     </div>
            

              {/* Using React Paginate */}

            </div>
            <div className='row mb-5'>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <ReactPaginate
                  previousLabel={<i className="fa-solid fa-angles-left"></i>}
                  nextLabel={<i className="fa-solid fa-angles-right"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  breakLinkClassName={"break-mef"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={offset - 1}

                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />

              </div>
              <div className='col-md-4'></div>
            </div>


          </div>


        </div>
       
      </div>

     
    
  )
}

export default Report
