// import '../static/default.css';
import React from 'react'
import { useEffect,useState,useRef } from 'react'
import '../index.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { Nav, NavDropdown } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import Login from './Login';
import Swal from 'sweetalert2';
//import About from './About';


const Userivrnavbar = (props) => {

    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/login');
        }
    }, [])



    const [number, setNumber] = useState();

    const refclicktocall = useRef(null)
    const clicktocall = async () => {

        refclicktocall.current.click();
        optionforagent();
        //alert("clicked>>>>>>>>>");
       
      }
   // const[userinfo,Setuserinfo]
    const [userinfodata, Setuserinfo] = useState();

    const userinfo = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/GetUserinfo", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
      
            headers: {
                'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        Setuserinfo(json.username)
       // console.log(json.length)
       
      }
      
      useEffect(() => {
        if (localStorage.getItem('token')) {
        userinfo()
        }
      }, [])


    const [opennav, setopennav] = useState(true);
    const toggleClass = () => {
        //alert("clicked");
        if (!opennav) {
            setopennav(true);
        } else {
            setopennav(false);
        }
        // alert("clicked"+opennav);

    }


    const { showAlert } = props
    let history = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        history('/login');
       // props.showAlert("Successfully Logout", "success");
       Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: "Successfully Logout",
        background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }


   const[agentid,setAgentid] = useState()


  
   
   const clicktocallivr = async(e) => {
    e.preventDefault();
    refclicktocall.current.click();
    console.log(">>>>>>>>>>>>>>>>agentid>>>>>>>>>>>>>>>"+agentid)
    console.log(">>>>>>>>>>>>>>>>number>>>>>>>>>>>>>>>>"+number)
    var formdata = new FormData()
    formdata.append('agentid', agentid)
    formdata.append('number', number)
    const response = await fetch(process.env.REACT_APP_IP+"/User/Clicktocallivr", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }

        setAgentid("")
        setNumber("")


    
   }
   



    const optionforagent = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/AllivrlistGroupagentByuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('agentopselectmodel');
        elelist.innerHTML = ""+('<option value="">NA</option>');


        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['agentnumber'] + ' | '+ json[i]['agentname'] +'</option>';
        }
    }



    let location = useLocation();
    return (
        <>

            {/* <h1>Agent</h1> */}
            {/* <About data={opennav.data} />  */}






            {!localStorage.getItem('token') ?
                <div className="" >



                </div> :
                <div className="" >

                    {(() => {
                        if (localStorage.getItem('roles') === 'ROLE_USER') {
                            return (

                                <>
                                    <div>

                                        {/* <div className="sidenav"> */}
                                        <nav className="navbar fixed-top navbar-expand-lg navbar-dark mybg-primary">
                                            <div className="container-fluid">



                                                <Link className="navbar-brand" to="/Dashboard"><i className="fa fa-home mx-1" aria-hidden="true"></i>Dashboard</Link>
                                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>

                                                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Home">
                                                                <i className="fa fa-home mr-15" aria-hidden="true"></i>Home</Link>
                                                        </li> */}


                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link " to="/">
                                                                <i className="fa fa-user-o mr-15" aria-hidden="true"></i>User</Link>

                                                        </li> */}

                                                       

                                                        {/* <Link className={`mynavlink ${location.pathname === "/about" ? "active" : ""}`} to="/about">
                                            
                                        <i className="fa fa-info-circle mr-15" aria-hidden="true"></i>About</Link> */}
                                                         <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/IvrReport">
                                                                <i className="fa fa-table mr-15" aria-hidden="true"></i>CallLog</Link>
                                                        </li>

                                                        <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Ivragents">
                                                                <i className="fa fa-user mr-15" aria-hidden="true"></i>Agents</Link>
                                                        </li>



                                                       
                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa fa-upload mr-15" aria-hidden="true"></i> Setting
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                            <Link className="dropdown-item  p-2" to="/Ivrsetting" >Ivr Setting</Link>
                                                                {/* <div className="dropdown-divider"></div>
                                                                <Link className="dropdown-item  p-2" to="/UploadAudio" >Upload Audio</Link>
                                                                <div className="dropdown-divider"></div>
                                                                <Link className="dropdown-item p-2" to="/UploadNumber" >UploadNumber</Link>
                                                                <div className="dropdown-divider"></div>
                                                               
                                                                <Link className="dropdown-item p-2" to="/AddAgent" >AddAgent</Link>
                                                                <div className="dropdown-divider"></div> */}

                                                            </div>
                                                        </li> 
                                                       

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Crm">
                                                                <i className="fa fa-database mr-15" aria-hidden="true"></i>CRM</Link>
                                                        </li> */}

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Ivr">
                                                                <i className="fa fa-tree mr-15" aria-hidden="true"></i>IVR</Link>
                                                        </li> */}

                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa fa-table mr-15" aria-hidden="true"></i> Ivr Report
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item p-2" to="/IvrReport">
                                                                    Report</Link>

                                                                <Link className="dropdown-item  p-2" to="/IvrFilterReport" >Filter Report</Link>
                                                                <div className="dropdown-divider"></div>



                                                            </div>


                                                        </li>

                                                        {/* <li className="nav-item dropdown p-2">                                                        
                                                                <i className="fa fa-users mr-15" aria-hidden="true"></i>  
                                                                <Link className="nav-link" to="/AddLead" >Leads</Link>                                               
                                                           
                                                        </li> */}

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/AddLead">
                                                                <i className="fa fa-users mr-15" aria-hidden="true"></i>Leads</Link>
                                                        </li> */}

                                                        <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Agent">
                                                                {/* <i className="fa fa-tree mr-15" aria-hidden="true"></i> */}
                                                                SwitchToOBD</Link>
                                                        </li>

                                                        {/* <li className="nav-item p-2 ms-5">
                                                        <select id="didid" className="form-select" */}
                                                        {/* // onChange={(e) => setWelcomeaudio(e.target.value)} */}
                                                            {/* >
                                                                <option className="text-center" value="All">All</option>
                                                                <option value="123456789">123456789</option>
                                                                <option value="1234567892">1234567892</option>  */}


                                                        {/* </select>

                                                        </li> */}


                                                    </ul>
                                                    <div className="nav-item float-right mx-4 p-2">
                                                    <Link className="nav-link "  to="/IvrUserProfile">
                                                             <i className="fa fa-lg fa-user mr-15 " aria-hidden="true" ></i> 
                                                            
                                                            {userinfodata} </Link>

                                                        

                                                    </div>

                                                    <div className="nav-item float-right mx-4 p-2">
                                                   
                                                        <Link className="nav-link bg-danger " onClick={handleLogout} to="/login">
                                                            <i className="fa fa-lg fa-sign-out mr-15 " aria-hidden="true" ></i>Logout</Link>



                                                    </div>

                                                </div>

                                            </div>
                                        </nav>



                                    </div>


                                </>
                            )
                        }  else {
                            return (
                                <>
                                <div>

                                    {/* <div className="sidenav"> */}
                                    <nav className="navbar fixed-top navbar-expand-lg navbar-dark mybg-primary">
                                        <div className="container-fluid">



                                            <Link className="navbar-brand" to="/Dashboard"><i className="fa fa-home mx-1" aria-hidden="true"></i>Dashboard</Link>
                                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>

                                            <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                                
                                               

                                                <div className="nav-item float-right mx-4 p-2">
                                               
                                                    <Link className="nav-link bg-danger " onClick={handleLogout} to="/login">
                                                        <i className="fa fa-lg fa-sign-out mr-15 " aria-hidden="true" ></i>Logout</Link>



                                                </div>

                                            </div>

                                        </div>
                                    </nav>



                                </div>


                            </>
                            )
                        }
                    })()}


 {/*  click to call Model*/}
 <button ref={refclicktocall} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModalclicktocall">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModalclicktocall" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Click To Call</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          
                            <form className=" p-2" onSubmit={clicktocallivr}>

                               <label className="mb-2" htmlFor="number">Select Agent :</label>
                                <select id="agentopselectmodel" className="form-select mb-4" onChange={(e) => setAgentid(e.target.value)}
                                        required>
                                        {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                                <option value=""></option> */}


                                    </select>  
                               
                              
                                
                         <div className=" mb-2 ">
                            <label className=" mb-1" htmlFor="number">Number:</label>
                              <input type="text" className="form-control" id="number" placeholder="Enter number to Call"
                                  name="number" value={number}
                                  onChange={(e) => setNumber(e.target.value)} required />
                              
                          </div>
                         

                           
                             
                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="CALL" />
                                </div>

                            </form>
                        </div>
                        
                    </div>
                </div>
                </div>


                     <div className="slide-out-div">

							{/* <img className="mx-auto d-block" style="filter: invert(1);"
								th:src="@{/images/c2cn.png}"/> */}

                            <img className="mx-auto d-block "  src={require('../images/c2cn.png')} alt="null" onClick={(e) => clicktocall(e)}   />
                            Click To Call
                    </div>

                </div>
            }




        </>
    )
}

export default Userivrnavbar
