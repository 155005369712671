// import '../static/default.css';
import React from 'react'
import { useEffect,useState } from 'react'
import '../index.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { Nav, NavDropdown } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import Login from './Login';
import Swal from 'sweetalert2';
//import About from './About';


const Logoutnav = (props) => {

    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/login');
        }
    }, [])


   // const[userinfo,Setuserinfo]
    const [userinfodata, Setuserinfo] = useState();

    const userinfo = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/GetUserinfo", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
      
            headers: {
                'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        Setuserinfo(json.username)
       // console.log(json.length)
       
      }
      
      useEffect(() => {
        if (localStorage.getItem('token')) {
        userinfo()
        }
      }, [])


    const [opennav, setopennav] = useState(true);
    const toggleClass = () => {
        //alert("clicked");
        if (!opennav) {
            setopennav(true);
        } else {
            setopennav(false);
        }
        // alert("clicked"+opennav);

    }


    const { showAlert } = props
    let history = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        history('/login');
       // props.showAlert("Successfully Logout", "success");
       Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: "Successfully Logout",
        background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

    let location = useLocation();
    return (
        <>

            {/* <h1>Agent</h1> */}
            {/* <About data={opennav.data} />  */}






            {!localStorage.getItem('token') ?
                <div className="" >



                </div> :
                <div className="" >

                    {(() => {
                         
                            return (
                                <div>
                                    <nav className="navbar fixed-top navbar-expand-lg navbar-dark mybg-primary">
                                        <div className="container-fluid">
                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                             </ul>   
                                                    
                                                        <div className="nav-item float-right mx-4 p-2">
                                                            <Link className="nav-link bg-danger " onClick={handleLogout} to="/login">
                                                            <i className="fa fa-lg fa-sign-out mr-15 " aria-hidden="true" ></i>Logout</Link>
                                                        </div>
                                                    
                                           </div>
                                      </nav>
                                </div>
                            )
                                            })()}







                </div>
            }




        </>
    )
}

export default Logoutnav
