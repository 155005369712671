import React, { useContext } from 'react'
import { FormContext } from '../../FormContext';
const Select = ({ fieldid, fieldlabel, fieldplaceholder, fieldvalue, fieldoptions }) => {
    const { handleChange } = useContext(FormContext)

   // var array = fieldoptions.split(',');
    var array = listToAray(fieldoptions, ',');

    function listToAray(fullString, separator) {
        var fullArray = [];
      
        if (fullString !== undefined) {
          if (fullString.indexOf(separator) == -1) {
            fullArray.push(fullString);
          } else {
            fullArray = fullString.split(separator);
          }
        }
      
        return fullArray;
      }

    return (
        <>

<div className="col-md-6 col-12 mb-3">

            <label className="form-label">{fieldlabel}</label>
            <select className="form-select col-md-4" aria-label="Default select example"
                onChange={event => handleChange(fieldid, event)}
            >
                <option >Select</option>
                 {array.length > 0 && array.map((option, i) =>
                    <option value={array[i]} key={i}>{array[i]}</option>

                )} 
            </select>

            </div>
        </>
    )
}

export default Select
