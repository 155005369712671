

import '../App.css';
import { useEffect, useState} from 'react'
import { useNavigate ,Link} from "react-router-dom";
 import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';


import ReactPaginate from 'react-paginate';
import axios from 'axios'
import Login from './Login';
import Navbar from './Navbar';
import Userivrnavbar from './Userivrnavbar';
import receivedimg from '../images/Inbound_calls.png'
import missedimg from '../images/missed_calls.png'
import { Doughnut } from 'react-chartjs-2';

import {
  
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
   
  } from 'chart.js';

 const Ivrdashboard = (props) => {

    let history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/login');
        }
    }, [])

    useEffect(() => {
        document.title = "Ivr Dashboard"
     }, []);

     const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Bar Chart',
          },
        },
      };


      
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const barchartdata = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        //data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dataset 2',
       // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

      



     ChartJS.register(ArcElement, Tooltip, Legend);

     ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );








    const [opennav, setopennav] = useState();
    const toggleClass = () => {
        //alert("clicked");
        if (!opennav) {
            setopennav(true);
        } else {
            setopennav(false);
        }
        // alert("clicked"+opennav);

    }
    const [postsPerPage] = useState(10);
    const [postsPerPage2] = useState(10);
    const [offset, setOffset] = useState(1);
    const [offset2, setOffset2] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [posts2, setAllPosts2] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [pageCount2, setPageCount2] = useState(0)

    const[totalcall,settotalcall] = useState(0)
    const gettotalcall = async () => {  
        const res = await axios.get(process.env.REACT_APP_IP+`/User/counttotalcall`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalcall(data)
    
      }

      useEffect(() => {
        gettotalcall()
    }, [])
    const[totalagent,settotalagent] = useState(0)
    const gettotalcallagent = async () => {  
        const res = await axios.get(process.env.REACT_APP_IP+`/User/CountAllivrlistGroupagentByuserid`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalagent(data)
    
      }

      useEffect(() => {
        gettotalcallagent()
    }, [])



    

    const[totalmissed,settotalmissed] = useState(0)
    const gettotalmissed = async () => {  
        const res = await axios.get(process.env.REACT_APP_IP+`/User/counttotalmissedcall`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalmissed(data)
    
      }

      useEffect(() => {
        gettotalmissed()
    }, [])
    

    // const getAllPosts = async () => {
    //     const res = await axios.get(`http://localhost:8080/withouttokenhello`)
    //     const data = res.data;
    //     const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    //     // For displaying Data
    //     const postData = getPostData(slice)

    //     // Using Hooks to set value
    //     setAllPosts(postData)
    //     setPageCount(Math.ceil(data.length / postsPerPage))
    // }


    const[totalans,settotalans] = useState(0)   

    const gettotalans = async () => {  
        const res = await axios.get(process.env.REACT_APP_IP+`/User/counttotalanscall`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalans(data)
    
      }

      useEffect(() => {
        gettotalans()
    }, [])

    const[totalnwh,settotalnwh] = useState(0) 
    const gettotalnwh = async () => {  
        const res = await axios.get(process.env.REACT_APP_IP+`/User/counttotalnwhcall`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalnwh(data)
    
      }

      useEffect(() => {
        gettotalnwh()
    }, [])
    const[totalout,settotalout] = useState(0) 

// chart 
const chartdata = {
    labels: ['ANSWER', 'MISSEDCALL', 'N.W.H CALL'],
    datasets: [
      {
        label: '# of Votes',
        data: [totalans, totalmissed, totalnwh],
        backgroundColor: [
            'green',
        //   'rgba(255, 99, 132, 0.2)',
         'red',
        //   'rgba(54, 162, 235, 0.2)',
        'yellow',
        //   'rgba(255, 206, 86, 0.2)',
          'blue'
        //   'rgba(75, 192, 192, 0.2)',
          
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
         
        ],
        borderWidth: 1,
      },
    ],
  };



  const[totalinccall,settotalinccall] = useState(0)
  const gettotalinccall = async () => {  
      const res = await axios.get(process.env.REACT_APP_IP+`/User/counttotalinccall`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
  
      const data = res.data;
      settotalinccall(data)
  
    }

    useEffect(() => {
        gettotalinccall()
  }, [])


  const[totaloutcall,settotaloutcall] = useState(0)
  const gettotaloutcall = async () => {  
      const res = await axios.get(process.env.REACT_APP_IP+`/User/counttotaloutcall`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
  
      const data = res.data;
      settotaloutcall(data)
  
    }

    useEffect(() => {
        gettotaloutcall()
  }, [])



// chart 
const chartdatacalltype = {
    labels: ['INCOMING', 'OUTGOING'],
    datasets: [
      {
        label: '# of Votes',
        data: [totalinccall,totaloutcall ],
        backgroundColor: [
            'green',
        //   'rgba(255, 99, 132, 0.2)',
         'blue'
        //   'rgba(54, 162, 235, 0.2)',
        // 'yellow',
        //   'rgba(255, 206, 86, 0.2)',
        //   'blue'
        //   'rgba(75, 192, 192, 0.2)',
          
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
         
        ],
        borderWidth: 1,
      },
    ],
  };






    const getAllPosts = async () => {  
        const res = await axios.get(process.env.REACT_APP_IP+`/User/AllReport?pageNumber=${offset - 1}&&
        size=10`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
    
        const postData = getPostData(data)
   
        setAllPosts(postData)
      
        setPageCount(Math.ceil(data.totalPages))
      }


     const getAllPosts2 = async () => {
         const res2 = await axios.get(process.env.REACT_APP_IP+`/User/getRecord`,
         { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
         const data2 = res2.data;
         const slice2 = data2.slice(offset2 - 1, offset2 - 1 + postsPerPage2)

         // For displaying Data
         const postData2 = getPostData2(slice2)

    //     // Using Hooks to set value
        setAllPosts2(postData2)
         setPageCount2(Math.ceil(data2.length / postsPerPage2))
     }


    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handlePageClick2 = (event) => {
        const selectedPage2 = event.selected;
        setOffset2(selectedPage2 + 1)
    };

     useEffect(() => {
        if (localStorage.getItem('token')) {
         getAllPosts()
        }
     }, [offset])

     useEffect(() => {
         getAllPosts2()
     }, [offset2])


    const { showAlert } = props

    const getPostData2 = (data2) => {

        return (
            <>



                {/* <h2>This is home page</h2> */}

                {/* call */}
                

                <div className= "col-sm-12 col-12 my-2">
                        <h5>Live Calls</h5>
                        <div className="table-responsive">
                            <table className="table table-hover bg-darkblue text-white table-striped ">
                                <thead>
                                    <tr>
                                        <th>Caller</th>
                                        <th>Source</th>
                                        <th>Agent</th>
                                        <th>Key Press</th>
                                        <th>Duration</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data2.map(page => {
                                        return (

                                            <tr key={page.id}>
                                            <td>8810692314</td>
                                            <td>1207182964</td>
                                            <td>9560179221</td>
                                            <td>1</td>
                                            <td>00:00:45</td>
                                        </tr>
                                            // <tr key={page.id}>
                                            //     <td>{page.list}</td>
                                            //     <td>{page.pendingdata}</td>
                                            //     <td>{page.dialeddata}</td>
                                            //     <td>{page.totaldatainlist}</td>
                                            //     <td>{page.totaldatainlist}</td>
                                            // </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                

            </>




        )
    }
    const getPostData = (data) => {

        return (
            <>
          

             

                {/* <h2>This is home page</h2> */}

                {/* call */}
                <div className="row " >

                   

                    <div className="col-sm-12 col-12 my-2" >
                        <h5>Recent Calls</h5>
                        <div className="table-responsive">
                            <table className="table table-hover  table-striped  bg-darkblue text-white">
                                <thead>
                                    <tr>
                                       
                                        <th>Caller</th>
                                        <th>Agent</th>
                                        <th>Status</th>
                                        <th>Date/Time</th>
                                        <th>Call</th>
                                        <th>Location</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.content.map(page => {
                                        return (
                                            <tr key={page.id}>
                                                
                                                <td>{page.CustomerNumber}</td>
                                                <td>{page.AgentNumber}</td>
                                                <td>{page.dialstatus}</td>
                                                <td>{page.dialtime}</td>
                                                <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}}  ></i></td>
                                                <td></td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </>




        )
    }


    return (


        
        <div>

<Userivrnavbar />

{
            localStorage.getItem('token') ?

            <div className="container-fluid mt-5 p-2 mybgcolor">
                

                    {/* <h1>This is Home page</h1> */}

                    <div className="row mt-4 ">

                            <div className="col-lg-2 col-6 text-white">

                                <div className="small-box mybg-danger">
                                    <div className="inner">
                                        <h3>{totalcall}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-phone"></i>
                                        </div>

                                        <p>Total</p>

                                    </div>

                                    <Link className="small-box-footer" to="/Ivrreport" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link>
                                
                                
                                </div>
                            </div>
          
                            <div className="col-lg-2 col-6 text-white">

                                <div className="small-box mybg-success">
                                    <div className="inner">
                                        <h3>{totalans}</h3>
                                        <div className='icon'>
                                            <div className="iconmy float-end bg-success">
                                                
                                                    <img  src={receivedimg}/>
                
                                            </div>
                                        </div>

                                        <p>Received</p>

                                    </div>

                                    <Link className="small-box-footer" to="/Ivrreport" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link>
                                
                                
                                
                                </div>
                            </div>

                            <div className="col-lg-2 col-6 text-white">

                                <div className="small-box mybg-warning">
                                    <div className="inner">
                                        <h3>{totalmissed}</h3>

                                        <div className='icon'>
                                            <div className="iconmy float-end bg-danger">
                                                
                                                    <img  src={missedimg}/>
                
                                            </div>
                                        </div>

                                        <p>Missed</p>

                                    </div>

                                    <Link className="small-box-footer" to="/Ivrreport" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link>
                                
                                
                                
                                </div>
                            </div>



                            <div className="col-lg-2 col-6 text-white">

                                <div className="small-box mybg-info">
                                    <div className="inner">
                                        <h3>{totalnwh}</h3>
                                        <div className="icon">
                                            {/* <i className=" fa fa-volume-control-phone"></i> */}
                                            <i className=" fa fa-phone"></i>
                                        </div>

                                        <p>N.W.H Calls</p>

                                    </div>

                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-2 col-6 text-white">

                                <div className="small-box mybg-info">
                                    <div className="inner">
                                        <h3>{totaloutcall}</h3>
                                        <div className="icon">
                                            {/* <i className=" fa fa-volume-control-phone"></i> */}
                                            <i className=" fa fa-phone"></i>
                                        </div>

                                        <p>Outbound Calls</p>

                                    </div>

                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-2 col-6 text-white">

                                <div className="small-box mybg-info">
                                    <div className="inner">
                                        <h3>{totalagent}</h3>
                                        <div className="icon">
                                            {/* <i className=" fa fa-volume-control-phone"></i> */}
                                            <i className=" fa fa-user"></i>
                                        </div>

                                        <p>Total Agents</p>

                                    </div>

                                   
                                    <Link className="small-box-footer" to="/Ivragents" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link>
                                
                               
                               
                                </div>
                            </div>
                       
                        
                       
                    </div>
                    {/* Display all the posts */}
                    <div className="row" style={{ marginLeft: "-5px", marginRight: "-5px" }}>
                <div className="col-sm-6 col-12 my-2" >
                    {posts}

                    <div className="row">
                    <div className="col-sm-2" ></div>
                    <div className="col-sm-6" >
                    
                    {/* Using React Paginate */}
                    <ReactPaginate
                        previousLabel={<i className="fa-solid fa-angles-left text-black"></i>}
                        nextLabel={<i className="fa-solid fa-angles-right text-black"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                         </div>
                         <div className="col-sm-2" ></div>  
                         </div>

                </div>
                <div className="col-sm-6 col-12 my-2" >
                    {posts2}



                    {/* Using React Paginate */}

                    <div className="row">
                    <div className="col-sm-2" ></div>
                    <div className="col-sm-8" >
                    <ReactPaginate
                        previousLabel={<i className="fa-solid fa-angles-left text-black"></i>}
                        nextLabel={<i className="fa-solid fa-angles-right text-black"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount2}
                        onPageChange={handlePageClick2}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination2"}
                        activeClassName={"active"} />
                     </div>

                          <div className="col-sm-2" ></div>

                        </div>


                </div>
                </div>


            </div>:<div>

                <Login />
            </div>

}

<div className='row p-5'>
     <div className='col-md-2'>
     </div>
     <div className='col-md-4 p-5'>
             <Pie data={chartdata} />
     </div>

     <div className='col-md-4 p-5'>
           <Doughnut data={chartdatacalltype} />
    </div>
    <div className='col-md-2'>
     </div>


</div>




        </div> 

    



    )



}
export default Ivrdashboard
