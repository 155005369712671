import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { CSVLink, CSVDownload } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import Swal from 'sweetalert2';




const UploadNumber = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Upload Number"
     }, []);


    const csvData = [
        ["Number"]
    ];

    const el = useRef();

    const [uploadFile, setUploadFile] = useState();
    const [listname, setListname] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const uploadaudioonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('file', uploadFile)
        formData.append('listname', listname)


        const response = await fetch(process.env.REACT_APP_IP+"/User/UploadNumber", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        getAllPosts()

        el.current.value = "";



        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }


     // deleteCampaign
     const deletelist = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteList", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
       const json = await response.json()
       console.log(json.msg)
       if(json.type==="OK")
        {
           // props.showAlert(json.msg, "success");
           Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: json.msg,
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 2500
          })
        }else{
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
               // title: 'Done Successfully ',
               //background: primary,
                showConfirmButton: false,
                timer: 2500
              })
        }
        
        getAllPosts()
        




    }


    const downloadsample = async (e) => {
        e.preventDefault();

        const response = await fetch(process.env.REACT_APP_IP+"/User/api/foo.csv", {
            method: 'GET',
            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        });
        const json = await response.json()
        // console.log(json)
        props.showAlert(" Downloaded Successfully ", "success");
        getAllPosts()
    }







    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllList?pageNumber=${offset - 1}&&size=5`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>

                <div className='row mt-1'>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card  mt-5 mybgcolor' style={{
                            minHeight: "500px"
                        }}>

                            <div className='card-header text-white'>
                                <h2>Existing List</h2>

                                <div className="table-responsive mt-3">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>ListName</th>

                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.listname}</td>

                                                        <td> <i className="far fa-trash-alt mx-2 btn btn-danger " onClick={() => deletelist(page.id)} ></i></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }


    return (


       <>
       <Navbar />
      

        <div className='row mt-4 mx-1'>


           

                {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
                <div className='col-md-3 col-12 text-white'>


                    <form className='card mybgcolor p-3' style={{
                         minHeight: "400px", marginTop: "50px"
                    }} onSubmit={uploadaudioonsubmit}>
                        <h4>Upload Number</h4>
                        <div className="mb-3 mt-3">
                            <label htmlFor="listname">List Name</label>
                            <input name="listname" type="text" className=" form-control form-control-sm"
                                onChange={(e) => setListname(e.target.value)} required />


                        </div>

                        <div className="mb-3 mt-3">


                            <input className=" form-control form-control-sm" ref={el}
                                id="formFileSm" name="file" type="file" onChange={onchangfun} required />
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                {/* <i className="fa-solid fa-download fa-2x btn btn-success  mt-3 " onClick={downloadsample} ></i> */}




                                <CSVLink className="btn btn-success mt-3" data={csvData} filename={"SampleFile.csv"} target="_blank" ><i className="fa-solid fa-download"></i> CSV Format</CSVLink>
                                {/* or  */}
                                {/* <CSVDownload data={csvData} target="_blank" />; */}
                            </div>
                            <div className='col-md-6'>
                                <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                            </div>

                        </div>



                    </form>

                </div>
            
              

                <div className="col-md-9 col-12">
                <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1.5 }}
                exit={{ opacity: 0, duration: 1.5 }}
            //   exit={{scale: 1,animate scale: 0}}

            >

                    {/* Display all the posts */}
                    {posts}

                    <div className="row">
                        <div className="col-md-3 col-12"></div>

                        <div className="col-md-6 col-12 mb-5">


                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={".."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}

                            />


                        </div>

                    </div>


                    </motion.div>
                </div>


           

        </div>
        </>
    )


}


export default UploadNumber
