import React from 'react'
import { useEffect,useState } from 'react'
import Logoutnav from './Logoutnav'
import Swal from 'sweetalert2'
import { Link, useLocation, useNavigate } from "react-router-dom";

const Service = () => {
    let history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/login');
        }
    }, [])

    useEffect(() => {
      document.title = "Select Services"
   }, []);
   
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        history('/login');
       // props.showAlert("Successfully Logout", "success");
       Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: "Successfully Logout",
        background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

  return (


    <div style={{ marginTop: "65px" }}>

        <Logoutnav />

    <div className='card'>
        <div className=' text-center font-weight-bold mb-5'>
               <h1>Service</h1> 
        </div>
      <div className='row mb-5'>
         <div className='col-md-6 col-6'>
            <div className='row'>
             <div className='col-md-6'></div>
              <div className='col-md-6'>
                     <h1>OBD</h1>
                     {/* <a className="btn btn-primary btn-sm waves-effect waves-light" >
                         <i className="fas fa-sign-in-alt fa-10x"></i>
                     </a> */}
                     <Link className="btn btn-primary btn-sm waves-effect waves-light" to="/Agent">
                     <i className="fas fa-sign-in-alt fa-10x"></i></Link>
				</div>	
                </div>

         </div>
         <div className='col-md-6 col-6'>
         <div className='row'>
             <div className='col-md-3'></div>
              <div className='col-md-6'>
                 <h1>IVR</h1>
                 <Link className="btn btn-primary btn-sm waves-effect waves-light" to="/Dashboard">
                     <i className="fas fa-sign-in-alt fa-10x"></i></Link>
              </div>
            </div>

             
         </div>

      </div>
    
      </div>
    
    
    
    </div>



  )
}

export default Service