import { React, useRef, useState, useEffect } from 'react'
import { useLocation, Link, useNavigate } from "react-router-dom";
import Userivrnavbar from './Userivrnavbar'
import Swal from 'sweetalert2'
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import TimePicker from 'react-time-picker';

const Ivragents = () => {
    let history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/login');
        }
    }, [])

    useEffect(() => {
        document.title = "Ivr Agents"
     }, []);


    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/AllivrGroupagentByuserid?pageNumber=${offset - 1}&&size=6`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    useEffect(() => {
        getAllPosts()
    }, [offset])
    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }


    const refaddgroupagent = useRef(null)
    const refaddgroupagentedit = useRef(null)

    let location = useLocation();

    const [agentname, setAgentname] = useState();
    const [agentnumber, setAgentnumber] = useState();
    const [extension, setExtension] = useState();

    const [did, setDid] = useState();
    const [groupname, setGroupname] = useState();
    const [starttime, setStarttime] = useState('10:00:00');
    const [endtime, setendtime] = useState('19:00:00');
    const [issun, setissun] = useState(false);

    const [mon, setmon] = useState(false);
    const [tue, settue] = useState(false);
    const [wed, setwed] = useState(false);
    const [thu, setthu] = useState(false);
    const [fri, setfri] = useState(false);
    const [sat, setsat] = useState(false);


    const editgroupfun = async (event) => {
        // event.preventDefault();
        setGroupname('')

        refaddgroupagentedit.current.click();
        //alert("clicked>>>>>>>>>");
        var formdata = new FormData()
        formdata.append('groupid', event)
        const response = await fetch(process.env.REACT_APP_IP+"/User/findGroupByid", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        setGroupname(json.groupname)


    }
    const addgroupfun = async (event) => {
        event.preventDefault();
       // setGroupname('')
       optionforgroup()
       optionfordid()
        refaddgroupagent.current.click();
        //alert("clicked>>>>>>>>>");

    }
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    }
    const handlesatChange = () => {
        setsat(!sat);

    }

    const handlefriChange = () => {
        setfri(!fri);

    }

    const handlethuChange = () => {
        setthu(!thu);

    }
    const handlewedChange = () => {
        setwed(!wed);

    }

    const handletueChange = () => {
        settue(!tue);

    }
    const handlesunChange = () => {
        setissun(!issun);

    }
    const handlemonChange = () => {
        setmon(!mon);

    }

    const updateGroup = () => {

    }
    const optionfordid = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/allDidbyuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('didselectmodel');
        elelist.innerHTML = ""+('<option value="">NA</option>');


        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['did'] + '">' + json[i]['did'] + '</option>';
        }
    }

    // useEffect(() => {
    //     optionfordid()
    // }, [])

    const optionforgroup = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('groupselectmodel');
        elelist.innerHTML = ""+('<option value="">NA</option>');

        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['groupname'] + '</option>';
        }
    }

    // useEffect(() => {
    //     optionforgroup()
    // }, [])




    const deleteGroup = async (event) => {
        //event.preventDefault();
        console.log("event>>>>>>>>" + event)
        var formdata = new FormData()
        formdata.append('id', event)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteIvrAgent", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        getAllPosts()

    }



    const submiteditgroup = async (e) => {
        e.preventDefault();
        refaddgroupagentedit.current.click();
        console.log("groupname>>>>>>>>" + groupname)
        console.log("starttime>>>>>>>>" + starttime)
        console.log("endtime>>>>>>>>" + endtime)
        console.log("issun>>>>>>>>" + issun)
        console.log("mon>>>>>>>>" + mon)
        console.log("tue>>>>>>>>" + tue)
        console.log("wed>>>>>>>>" + wed)
        console.log("thu>>>>>>>>" + thu)
        console.log("fri>>>>>>>>" + fri)
        console.log("sat>>>>>>>>" + sat)
        var formdata = new FormData()
        formdata.append('groupname', groupname)
        formdata.append('starttime', starttime)
        formdata.append('endtime', endtime)
        formdata.append('sun', issun)
        formdata.append('mon', mon)
        formdata.append('tue', tue)
        formdata.append('wed', wed)
        formdata.append('thu', thu)
        formdata.append('fri', fri)
        formdata.append('sat', sat)
        const response = await fetch(process.env.REACT_APP_IP+"/User/UpdateIvrGroup", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }


        setGroupname('')
        setissun(false)
        setmon(false)
        settue(false)
        setthu(false)
        setfri(false)
        setsat(false)

        getAllPosts()




    }

    const submitaddagent = async (e) => {
        e.preventDefault();
        refaddgroupagent.current.click();
        console.log("groupname>>>>>>>>" + groupname)
        console.log("agentname>>>>>>>>" + agentname)
        console.log("agentnumber>>>>>>>>" + agentnumber)
        console.log("extension>>>>>>>>" + extension)
        console.log("did>>>>>>>>" + did)
        var formdata = new FormData()
        formdata.append('groupid', groupname)
        formdata.append('agentname', agentname)
        formdata.append('agentnumber', agentnumber)
        formdata.append('extension', extension)
        formdata.append('did', did)



        const response = await fetch(process.env.REACT_APP_IP+"/User/AddIvrAgent", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }


        setGroupname("")
       
        getAllPosts()



    }

    const getPostData = (data) => {
        return (
            <>

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12 mt-4'>

                        <div className='card p-2 mt-2' style={{ backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)" }}>



                            <div className='card-header text-white'>
                                <h4 className='text-center'>Existing Agents</h4>

                                <div className="table-responsive mt-3">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>Source Number</th>
                                                <th>Agent Name</th>
                                                <th>Agent Number</th>
                                                <th>Agent Group</th>
                                                <th>Extension</th>
                                                <th>Date Added</th>
                                                <th>Call Attendant</th>

                                                <th>Action</th>
                                                {/* <th>Delete</th> */}


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.sourcenumber}</td>
                                                        <td>{page.agentname}</td>
                                                        <td>{page.agentnumber}</td>
                                                        <td>{page.groupname}</td>
                                                        <td>{page.extno}</td>
                                                        <td>{page.adddate}</td>
                                                        
                                                        <td></td>
                                                        

                                                        <td>
                                                            <button className=" mx-2 btn btn-primary disabled" onClick={() => editgroupfun(page.id)}>
                                                                <i className="far fa-edit" ></i>
                                                            </button>
                                                            <i className="far fa-trash-alt mx-2 btn btn-danger " onClick={() => deleteGroup(page.id)}></i>

                                                        </td>





                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }


    return (
        <>
            <Userivrnavbar />
            <div className='row' style={{ marginTop: "75px" }}>

                <div className='col-md-9'>

                </div>
                <div className='col-md-3'>
                    <div className='float-end'>
                        <button className='btn btn-primary rounded btn-sm  mx-1 ' onClick={addgroupfun}>
                            <i className="fa fa-user mx-1"></i>Add Agents</button>
                    </div>
                </div>

            </div>

            <div className='mt-3 card  text-black'>
                <ul className="nav ">
                    <li className="nav-item">
                        {/* <a className="nav-link active" href="#"><i className="fa fa-user mr-15" aria-hidden="true"></i>Agents</a> */}

                        <Link className={`${location.pathname === "/Ivragents" ? "nav-link active" : "nav-link"}`} to="/Ivragents" ><i className="fa fa-user mr-15" aria-hidden="true"></i>Agents</Link>

                    </li>
                    <li className="nav-item">

                        <Link className={`${location.pathname === "/Ivrgroups" ? "nav-link active" : "nav-link"}`} to="/Ivrgroups" ><i className="fa fa-users mr-15" aria-hidden="true"></i>Ivr Groups</Link>
                    </li>

                </ul>


                <div>

                    {/* add Agents */}


                    <button ref={refaddgroupagent} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                        Launch add Agents  modal
                    </button>
                    <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add New Agents</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <form className=" p-2" onSubmit={submitaddagent}>

                                        <div className=" mb-3 ">
                                            <i className="fa fa-link mx-1 mb-2"></i><b htmlFor="groupname">Source Number:</b>
                                            {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                            <select id="didselectmodel" className="form-select" onChange={(e) => setDid(e.target.value)}
                                                required>
                                                {/* <option value={euserid}>{euserid} | {eusername}</option>
                                        <option value=""></option> */}


                                            </select>

                                        </div>

                                        <div className=" mb-3 ">
                                            <i className="fa fa-user mx-1 mb-2"></i><b htmlFor="agentname">Agent Name:</b>
                                            <input type="text" className="form-control" id="agentname" placeholder="Enter agentname"
                                                name="agentname" value={agentname}
                                                onChange={(e) => setAgentname(e.target.value)} required />

                                        </div>
                                        <div className=" mb-3 ">
                                            <i className="fa fa-phone mx-1 mb-2"></i><b htmlFor="agentnumber">Agent Number:</b>
                                            <input type="text" className="form-control" id="agentnumber" placeholder="Enter agent number"
                                                name="agentnumber" value={agentnumber}
                                                onChange={(e) => setAgentnumber(e.target.value)} required />

                                        </div>
                                        <div className=" mb-3 ">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <b htmlFor="groupname">Group:</b>
                                                    {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                                    <select id="groupselectmodel" className="form-select" onChange={(e) => setGroupname(e.target.value)}
                                                        required>
                                                        {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                                                <option value=""></option> */}


                                                    </select>

                                                </div>
                                                <div className='col-md-6'>
                                                    <b htmlFor="extension">Extension:</b>
                                                    <input type="text" className="form-control" id="extension" placeholder="Extension"
                                                        name="extension" value={extension}
                                                        onChange={(e) => setExtension(e.target.value)}  />

                                                </div>

                                            </div>


                                        </div>
                                        
                                       



                                        <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Add Agent" />
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>


                    {/* Edit group model */}
                    <button ref={refaddgroupagentedit} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                        Launch add group  modal
                    </button>
                    <div className="modal fade" id="exampleModal4" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Edit Group</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <form className=" p-2" onSubmit={submiteditgroup}>

                                        <div className=" mb-3 ">
                                            <i className="fa fa-users mx-1 mb-2"></i><b htmlFor="groupname">Group Name:</b>
                                            <input type="text" className="form-control" id="number" placeholder="Enter groupname"
                                                name="groupname" value={groupname}
                                                onChange={(e) => setGroupname(e.target.value)} required />

                                        </div>
                                        <div className="dropdown-divider"></div>


                                        <div className="mb-3">
                                            <i className="fa fa-calendar mb-2" aria-hidden="true"></i><b className='mx-2'>Select Holidays:</b>
                                            <br></br>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" checked={issun} id="inlineCheckbox1" onChange={handlesunChange} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox1">sun</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" checked={mon} onChange={handlemonChange} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">mon</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" checked={tue} onChange={handletueChange} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">tue</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" checked={wed} onChange={handlewedChange} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">wed</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" checked={thu} onChange={handlethuChange} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">thu</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" checked={fri} onChange={handlefriChange} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">fri</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" checked={sat} onChange={handlesatChange} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">sat</label>
                                            </div>

                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <div className=" mb-3 ">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <i className="fa fa-clock-o" aria-hidden="true"></i><b className='mx-2'>From:</b>
                                                    <TimePicker format='HH:mm:ss' onChange={setStarttime} value={starttime} required='true' />
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='float-end'>
                                                        <i className="fa fa-clock-o" aria-hidden="true"></i><b className='mx-2'> To:</b>

                                                        <TimePicker format='HH:mm:ss' onChange={setendtime} value={endtime} required='true' />
                                                    </div>
                                                </div>
                                            </div>



                                        </div>



                                        <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Update Group" />
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>


            <div className="col-md-12 col-12">

                {/* Display all the posts */}
                {posts}

                <div className="row mb-5">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                           previousLabel={<i className="fa-solid fa-angles-left text-black"></i>}
                           nextLabel={<i className="fa-solid fa-angles-right text-black"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>
        </>

    )
}

export default Ivragents