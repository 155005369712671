import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate ,Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import Select from 'react-select'
import { Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
// import AddCampaignform from './AddCampaignform';


const Makegui = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    // const options = [
    //     { value: 'ii', label: 'Chocolate' },
    //     { value: 'hh', label: 'Strawberry' },
    //     { value: 'gg', label: 'Vanilla' }
    //   ]

    
    const optionsa = async (e) => {
        const resppe = await fetch("http://localhost:8080/User/RestAllAudioSelect", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const jsona = await resppe.json()
        console.log(jsona.length)

        var ele = document.getElementById('selmain');
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            ele.innerHTML = ele.innerHTML +
                '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        }


        var eleedit = document.getElementById('sel');
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            eleedit.innerHTML = eleedit.innerHTML +
                '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        }




    }

    const optionforlist = async (e) => {
        const respp = await fetch("http://localhost:8080/User/RestAllListname", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('list');
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['listname'] + '">' + json[i]['listname'] + '</option>';
        }

        var elelistedit = document.getElementById('sellist');
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelistedit.innerHTML = elelistedit.innerHTML +
                '<option  value="' + json[i]['listname'] + '">' + json[i]['listname'] + '</option>';
        }

        


    }

    useEffect(() => {
        optionforlist()
    }, [])

    useEffect(() => {
        optionsa()
    }, [])





    const [selectedOption, setSelectedOption] = useState(null);

    const ref = useRef(null)
    const refClose = useRef(null)
    const [ecampaignid, setEcampaignid] = useState()
    const [ecampaign, setEcampaign] = useState()
    const [ecampaignmode, setEcampaignmode] = useState()
    const [estarttime, setestarttime] = useState()
    const [eendtime, setEendtime] = useState()
    const [eselectaudio, setEeselectaudio] = useState()
    const [eselectlist,setEeselectlist] = useState()

    const [esubmitbutton, setesubmitbutton] = useState()

    // const { campaign, updateCampaign } = props;
   // deleteCampaign
    const deleteCampaign = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch("http://localhost:8080/User/DeleteCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
       const json = await response.json()
       console.log(json.msg)
       if(json.msg === "Success")
       {
        props.showAlert("Deleted Successfully ", "success");

       }else{

        props.showAlert("!!!! Error !!!!!!!!! ", "danger");
       }
        
        getAllPosts()
        




    }


    const updateCampaign = async (e) => {

        ref.current.click();
        //alert(e);
        const response = await fetch("http://localhost:8080/User/getCampaignById", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": e

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        // console.log(json);
        // console.log(json.campname);
        // console.log(json.campaign_mode);

        setEcampaign(json.campname)
        setEcampaignmode(json.campaign_mode)
        setEcampaignid(json.id)
        setestarttime(json.start_time)
        setEendtime(json.end_time)
        setEeselectaudio(json.audioid | json.audio_name)
        setEeselectlist(json.listname)
        setmaxtry(json.maxtry)
        
        //getAllPosts()
        //setIsToggled(!isToggled);




    }

    // const handleClick = (e)=>{ 
    //     editNote(e.id, e.ecampname, e.ecamp_mode)
    //     refClose.current.click();
    // }


    const submiteditform = async (e) => {
        e.preventDefault();

        console.log(ecampaignid + ">>>>>>>" + ecampaign + ">>>>>>>>>" + ecampaignmode + ">>>>>>>>>" + estarttime
            + ">>>>>>>>" + eendtime + ">>>>>>>>>>>" + eselectaudio)

            var formdata = new FormData()
            formdata.append('id', ecampaignid)
            formdata.append('campaign', ecampaign)
            formdata.append('campaign_mode', ecampaignmode)
            formdata.append('start_time', estarttime)
            formdata.append('end_time', eendtime)
            formdata.append('audioid', eselectaudio)
            formdata.append('listname', eselectlist)
            formdata.append('maxtry', maxtry)
            

            
            


            const response = await fetch("http://localhost:8080/User/EditCampaign", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    // 'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
    
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });

            ref.current.click();
            getAllPosts()

    }


    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
        const id = e
        // alert(id)
        const response = await fetch("http://localhost:8080/User/StartStopCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": id

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        props.showAlert("Done Successfully ", "success");
        getAllPosts()
        setIsToggled(!isToggled);


    }
    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(`http://localhost:8080/User/RestAllCamp?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");
    const [audio, setAudio] = useState();
    const[listname,setList] = useState();



    // const setAudiooption = async(event) =>{
    //     setAudio()
    //     console.log(event)

    // }
    const addcampaignSubmit = async (event) => {
        event.preventDefault();
        //alert(`The name you entered was: ${campname}`)

        const response = await fetch("http://localhost:8080/User/AddDeleteCampaignAction", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "campname": campname,
                "campaign_mode": campaign_mode,
                "maxtry": maxtry,
                "start_time": start_time,
                "end_time": end_time,
                "audioid": audio,
                "listname":listname
            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        props.showAlert(" Campaign Added Successfully ", "success");
        //history('/AddCampaign');
        // alert("Campaign Added");
        setformyalert(!formyalert);
        getAllPosts()
        setCampname('')



    }




    const getPostData = (data) => {
        return (
            <>

             <motion.div   
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}

               >

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12 mt-4'>

                        <div className='card p-2 mt-2' style={{ backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)" }}>



                            <div className='card-header text-white'>
                                <h4 className='text-center'>Existing Campaign</h4>

                                <div className="table-responsive mt-3">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>Campaign Name</th>
                                               
                                                <th>CRM Field</th>
                                                <th>View CRM</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.campname}</td>
                                                       
                                                        {/* <td>
                                                            <button className=" mx-2 btn btn-primary" onClick={() => updateCampaign()}>
                                                                <i className="far fa-edit" ></i>
                                                            </button>

                                                        </td> */}

                                                        <td> <Link to="/Editgui" state={ {id: page.id,name: page.campname}}>
                                                            <i className="far fa-edit  btn btn-primary " aria-hidden="true" ></i>
                                                          
                                                            </Link></td>
                                                        

                                                        <td> <Link to="/Editgui" state={ {id: page.id,name: page.campname}}>
                                                            <i className="fa fa-eye btn btn-primary " aria-hidden="true" ></i>
                                                          
                                                            </Link></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                </motion.div>
            </>

        )

    }


    return (




        <div className='row mt-5'>


            <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit campaign</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className=" p-2" onSubmit={submiteditform}>
                                <div className="mb-2">

                                    <input type="text" className="form-control" id="ecampaignid" name="ecampaignid" value={ecampaignid}
                                        onChange={(e) => setEcampaignid(e.target.value)} required={true} readOnly={true} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="ecampaign" name="ecampaign" value={ecampaign}
                                        onChange={(e) => setEcampaign(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="description" className="form-label">Mode</label>
                                    {/* <input type="text" className="form-control" id="ecampaignmode" name="ecampaignmode" value={ecampaignmode}
                                     onChange={(e) => setEcampaignmode(e.target.value)}  required/> */}
                                    <div className="mb-3">
                                        <select className="form-select" name="campaign_mode" value={ecampaignmode}
                                            onChange={(e) => setEcampaignmode(e.target.value)} >
                                            <option value="NA">Select Campaign Mode</option>
                                            <option value="OBDwithKeypress">OBD with Keypress</option>
                                            <option value="OBDwithcallpatch">OBD & callpatching</option>
                                        </select>

                                    </div>
                                </div>

                                <div className=" mb-2 ">
                                      <label htmlFor="campname">Maxtry</label>
                                        <input type="text" className="form-control" id="maxtry" placeholder="Enter maxtry"
                                            name="maxtry" value={maxtry}
                                            onChange={(e) => setmaxtry(e.target.value)} />
                                        
                                    </div>
                                <div className="mb-2">
                                    <label htmlFor="campname">Start Time</label>
                                    <input type="text" className="form-control" id="start_time" placeholder="Enter start_time"
                                        name="start_time" value={estarttime}
                                        onChange={(e) => setestarttime(e.target.value)} />

                                </div>

                                <div className="mb-2">
                                    <label htmlFor="end_time">End Time</label>
                                    <input type="text" className="form-control" id="end_time" placeholder="Enter start_time"
                                        name="end_time" value={eendtime}
                                        onChange={(e) => setEendtime(e.target.value)} />

                                </div>
                                <div className="mb-2">
                                    <label  className="form-label">Select Audio:</label>
                                    <select id="sel" className="form-select" name="audio"
                                        value={eselectaudio}
                                        onChange={(e) => setEeselectaudio(e.target.value)} required>
                                        <option value="">-- Select --</option>
                                        <option value="NA">NA</option>

                                    </select>
                                </div>


                                <div className="mb-2">
                                    <label  className="form-label">Select List:</label>
                                    <select id="sellist" className="form-select" name="listname"
                                        value={eselectlist}
                                        onChange={(e) => setEeselectlist(e.target.value)} required>
                                        <option value="">-- Select List--</option>
                                        <option value="NA">NA</option>

                                    </select>
                                </div>




                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                            {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                        </div>
                    </div>
                </div>
            </div>

           


            <div className="col-md-12 col-12">

                {/* Display all the posts */}
                {posts}

                <div className="row mb-5">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>




        </div>
    )


}


export default Makegui
