import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import axios from 'axios'
import Navbar from './Navbar';

// import AddCampaignform from './AddCampaignform';


const AddUser = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })


   
   // const [selectedOption, setSelectedOption] = useState(null);

    const ref = useRef(null)
  


    const [euserprofileid, setEprofileid] = useState()
    const [ename, setEname] = useState("")
    const [email, setEmail] = useState("")
    
    const [eaddress, setEeaddress] = useState("NA")
    const [emobile, setEemobile] = useState("NA")
    const [esip, setEesip] = useState("NA")
    const [edid, setEedid] = useState("NA")
    const [ecampaign,setEecampaign] = useState("NA")
    const [ebackp,setEebackp] = useState("NA")
    const [evalidity,setEevalidity] = useState("NA")
    const [eobdpulse,setEeobdpulse] = useState("NA")
    const [evoicecredit,setEevoicecredit] = useState("NA")
    const [evoicecreditconsume,setEevoicecreditconsume] = useState("NA")

    const updateCampaign = async (e) => {

        ref.current.click();
        //alert(e);
        const response = await fetch(process.env.REACT_APP_IP+"/Admin/getUserById", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": e

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
         console.log(json);
        // console.log(json.campname);
        // console.log(json.campaign_mode);

         setEprofileid('')
        setEname('')
        setEeaddress('')
        
        setEemobile('')
        setEesip('')
        setEedid('')
        setEecampaign('')
        setEebackp('')
        setEevalidity('')
        setEeobdpulse('')
        setEevoicecredit('')
        setEevoicecreditconsume('')
        setEmail('')
        setEprofileid(json.id)
        
        setEname(json.name)
        setEmail(json.email)
        setEeaddress(json.address)
        
        setEemobile(json.mobile)
        setEesip(json.sip)
        setEedid(json.did)
        setEecampaign(json.campaign)
        setEebackp(json.backp)
        setEevalidity(json.validity)
        setEeobdpulse(json.obdpulse)
        setEevoicecredit(json.voicecredit)
        setEevoicecreditconsume(json.voicecreditconsume)

    }

    // const handleClick = (e)=>{ 
    //     editNote(e.id, e.ecampname, e.ecamp_mode)
    //     refClose.current.click();
    // }


    const submiteditform = async (e) => {
        e.preventDefault();

        console.log(euserprofileid + ">>>>>>>" + ename + ">>>>>>>>>" + ecampaign + ">>>>>>>>>" + eaddress
            + ">>>>>>>>" + emobile + ">>>>>>>>>>>" + esip+ ">>>>>>>>>>>" + edid+ ">>>>>>>>>>>" + ebackp
            + ">>>>>>>>>>>" + evalidity+ ">>>>>>>>>>>" + eobdpulse+ ">>>>>>>>>>>" + evoicecredit+">>>>>email>>>>>"+email)

            var formdata = new FormData()
            formdata.append('id', euserprofileid)
            formdata.append('name', ename)
            formdata.append('email', email)
            formdata.append('campaign', ecampaign)
            formdata.append('address', eaddress)
            formdata.append('mobile', emobile)
            formdata.append('sip', esip)
            formdata.append('did', edid)
            formdata.append('backp', ebackp)
            formdata.append('validity', evalidity)
            formdata.append('obdpulse', eobdpulse)
            formdata.append('voicecredit', evoicecredit)
            formdata.append('voicecreditconsume', evoicecreditconsume)
            
            
            


            const response = await fetch(process.env.REACT_APP_IP+"/Admin/EditUserProfile", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    // 'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
    
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            console.log(response)

            ref.current.click();
            getAllPosts()

    }


   
    
    
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/RestAllUser?pageNumber=${offset - 1}&&size=6`,
         {
             headers: {
                 "Authorization": `Bearer ${localStorage.getItem('token')}`
                 }
         })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [mob, setMob] = useState("");
    const [emailuser, setEmailuser] = useState("");

    const [roles, setRoles] = useState("");


    



    // const setAudiooption = async(event) =>{
    //     setAudio()
    //     console.log(event)

    // }
    const addcampaignSubmit = async (event) => {
        event.preventDefault();
        //alert(`The name you entered was: ${campname}`)

        const response = await fetch(process.env.REACT_APP_IP+"/Admin/AddUser", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "username": username,
                "password": password,
                "mobile": mob,
                "email": emailuser,
                "roles": roles
                
            })
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if(json.type==="OK")
        {
           // props.showAlert(json.msg, "success");
           Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: json.msg,
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 2500
          })
        }else{
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
               // title: 'Done Successfully ',
               //background: primary,
                showConfirmButton: false,
                timer: 2500
              })
        }
       
        //history('/AddCampaign');
        // alert("Campaign Added");
        setformyalert(!formyalert);
        getAllPosts()
        // setEprofileid('')
        // setEname('')
        // setEeaddress('')
        
        // setEemobile('')
        // setEesip('')
        // setEedid('')
        // setEecampaign('')
        // setEebackp('')
        // setEevalidity('')
        // setEeobdpulse('')
        // setEevoicecredit('')
        // setEevoicecreditconsume('')



    }




    const getPostData = (data) => {
        return (
            <>

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12 mt-4'>

                        <div className='card p-2 mt-2' style={{ backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)" }}>



                            <div className='card-header text-white'>
                                <h4 className='text-center'>Existing User</h4>

                                <div className="table-responsive mt-3">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>User Name</th>
                                                <th>User Type</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Email</th>
                                                <th>Total Credit</th>
                                                <th>Credit Remain</th>
                                                <th>Edit</th>
                                                {/* <th>Delete</th> */}


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.username}</td>
                                                        <td>{page.roles.substring(5)}</td>
                                                        <td>{page.mobile}</td>
                                                        
                                                        <td>{page.mobile}</td>
                                                        <td>{page.email}</td>
                                                        <td>{page.userpro.voicecredit}</td>
                                                        <td>{page.userpro.voicecreditconsume}</td>
                                                        
                                                        <td>
                                                            <button className=" mx-2 btn btn-primary" onClick={() => updateCampaign(page.userpro.id)}>
                                                                <i className="far fa-edit" ></i>
                                                            </button>

                                                        </td>
                                                        {/* <td> <i className="far fa-trash-alt mx-2 btn btn-danger" onClick={() => deleteCampaign(page.id)}></i></td> */}




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }


    return (

<>
<Navbar />



        <div className='row mt-5'>


            <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit/View User Profile</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                       
                            <form className=" p-2" onSubmit={submiteditform}>
                            <div className='row'>
                            <div className='col-md-6'>
                                <div className="mb-2">
                                <label htmlFor="title" className="form-label">Id</label>
                                    <input type="text" className="form-control" id="euserprofileid" name="euserprofileid" value={euserprofileid}
                                        onChange={(e) => setEprofileid(e.target.value)} required={true} readOnly={true} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="ename" name="ename" value={ename}
                                        onChange={(e) => setEname(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" name="ename" value={email}
                                        onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Address</label>
                                    <input type="text" className="form-control" id="eaddress" name="eaddress" value={eaddress}
                                        onChange={(e) => setEeaddress(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Mobile</label>
                                    <input type="text" className="form-control" id="emobile" name="emobile" value={emobile}
                                        onChange={(e) => setEemobile(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                <label htmlFor="title" className="form-label">Sip</label>
                                    <input type="text" className="form-control" id="esip" name="esip" value={esip}
                                        onChange={(e) => setEesip(e.target.value)} required={true}  />
                                    </div>
                                    <div className="mb-2">
                                            <label htmlFor="title" className="form-label">Did</label>
                                            <input type="text" className="form-control" id="edid" name="ename" value={edid}
                                                onChange={(e) => setEedid(e.target.value)} required />
                                    </div>
                                   
                               </div> 
                            <div className='col-md-6'>
                               
                            <div className="mb-2">
                                            <label htmlFor="title" className="form-label">Campaign</label>
                                            <input type="text" className="form-control" id="ecampaign" name="ecampaign" value={ecampaign}
                                                onChange={(e) => setEecampaign(e.target.value)} required />
                                    </div>
                                <div className="mb-2">
                                        <label htmlFor="title" className="form-label">Backup</label>
                                        <input type="text" className="form-control" id="ecampaign" name="ebackp" value={ebackp}
                                            onChange={(e) => setEebackp(e.target.value)} required />
                                </div>

                                <div className="mb-2">
                                        <label htmlFor="title" className="form-label">Validity</label>
                                        <input type="text" className="form-control" id="evalidity" name="evalidity" value={evalidity}
                                            onChange={(e) => setEevalidity(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                        <label htmlFor="title" className="form-label">OBD Pulse</label>
                                        <input type="text" className="form-control" id="eobdpulse" name="eobdpulse" value={eobdpulse}
                                            onChange={(e) => setEeobdpulse(e.target.value)} required />
                                </div>

                                <div className="mb-2">
                                        <label htmlFor="title" className="form-label">Credit</label>
                                        <input type="text" className="form-control" id="evoicecredit" name="evoicecredit" value={evoicecredit}
                                            onChange={(e) => setEevoicecredit(e.target.value)} required />
                                </div>

                                <div className="mb-2">
                                        <label htmlFor="title" className="form-label">Credit Consume</label>
                                        <input type="text" className="form-control" id="evoicecreditconsume" name="evoicecreditconsume" value={evoicecreditconsume}
                                            onChange={(e) => setEevoicecreditconsume(e.target.value)} required />
                                </div>

                                
                                </div>

                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </div>
                                </div>
                            </form>
                            
                        </div>
                        <div className="modal-footer">
                            {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                            {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                        </div>
                    </div>
                </div>
            </div>

            {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
            <div className='col-md-3 col-12 mt-4'>
                <form className='card p-3 mt-1 mx-1' style={{
                    backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)"
                   // , minHeight: "700px"

                }} onSubmit={addcampaignSubmit} >
                    <div className='card-header text-white'>
                        <h4>Add User</h4>
                    </div>
                    <div className="form-floating mb-2 ">
                        <input type="text" className="form-control" id="username" placeholder="Enter username"
                            name="username" value={username}
                            onChange={(e) => setUsername(e.target.value)} required />
                        <label htmlFor="username">User Name</label>
                    </div>


                    <div className="form-floating mb-2 ">
                        <input type="text" className="form-control" id="password" placeholder="Enter Password"
                            name="password" value={password}
                            onChange={(e) => setPassword(e.target.value)} required />
                        <label htmlFor="password">Password</label>
                    </div>

                    <div className="form-floating mb-2 ">
                        <input type="text" className="form-control" id="password" placeholder="Enter Mobile"
                            name="password" value={mob}
                            onChange={(e) => setMob(e.target.value)} required />
                        <label htmlFor="password">Mobile</label>
                    </div>

                    <div className="form-floating mb-2 ">
                        <input type="text" className="form-control" id="password" placeholder="Enter Email"
                            name="password" value={emailuser}
                            onChange={(e) => setEmailuser(e.target.value)} required />
                        <label htmlFor="password">Email</label>
                    </div>

                    {/* <div className="form-floating mb-3 mt-1">
                            <input type="text" className="form-control" id="campaign_mode" placeholder="Enter campaign_mode"
                             name="campaign_mode"  value={campaign_mode} 
                             onChange={(e) => setcampaign_mode(e.target.value)} />
                            <label htmlFor="campaign_mode">Campaign Mode</label>
                        </div> */}
                    <div className="form-floating mb-2">
                        <select className="form-select" name="roles" value={roles}
                            onChange={(e) => setRoles(e.target.value)} >
                            <option value="">Select User Type</option>
                            <option value="ROLE_USER">Client</option>
                            <option value="ROLE_APIOBDUSER">OBDAPI</option>
                        </select>
                        <label htmlFor="usertype" className="form-label">User Type:</label>
                    </div>
                   


                    


                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                </form>

            </div>


            <div className="col-md-9 col-12">

                {/* Display all the posts */}
                {posts}

                <div className="row mb-5">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>




        </div>
        </>
        
    )


}


export default AddUser
