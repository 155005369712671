import React from 'react'
import { useLocation } from 'react-router-dom';
import { useRef,useEffect } from 'react';

const Paytmpay = () => {

    const formRef = useRef(null);
   // e.preventDefault();
    const location = useLocation();
    const state = location.state;
   // console.log(">>>>>amount>>>"+state.amount);
    console.log(">>>>>amount>>>"+state.orderid);
    console.log(">>>>>amount>>>"+state.token);


    useEffect(() => {
        formRef.current.submit();
      }, []);
    // document.paytm.submit(); 


  return (
    <>
     <div>Paytmpay 
    <form ref={formRef} method="post" action={'https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=FLEXFO58504914971178&orderId='+state.orderid}>
         <table border="1">
            <tbody>
               <input type="hidden" name="mid" value="FLEXFO58504914971178" />
               <input type="hidden" name="orderId" value={state.orderid} />
               <input type="hidden" name="txnToken" value={state.token} />
            </tbody>
         </table>
        
      </form>

      </div>
    </>
   
  )
}

export default Paytmpay