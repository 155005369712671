 import './App.css';
// import './static/default.css';
import {
  BrowserRouter as Router,
  Routes,Navigate,
  Route
} from "react-router-dom";
import Navbar from './components/Navbar';
import { Home } from './components/Home';
import About from './components/About';
import AddCampaign from './components/AddCampaign';

import UploadAudio from './components/UploadAudio';

import UploadNumber from './components/UploadNumber';
import AddAgent from './components/AddAgent';





import NoteState from './context/notes/NoteState';
import Alert  from './components/Alert';
import Signup from './components/Signup';
import Login from './components/Login';
import Report from './components/Report';
import FilterReport from './components/FilterReport';


import {useState} from 'react';
import AddUser from './components/AddUser';
import ChannelSetting from './components/ChannelSetting';
import AddLead from './components/AddLead';
import LeadCalllog from './components/LeadCalllog';
import UploadLead from './components/UploadLead';
import Makegui from './components/Makegui';
import Editgui from './components/Editgui';
import Crm from './components/Crm';
import Profile from './components/Profile';
import Ivr from './components/Ivr';
import Service from './components/Service';
import Ivrdashboard from './components/Ivrdashboard';
import Ivragents from './components/Ivragents';
import Ivrgroups from './components/Ivrgroups';
import Didsettings from './components/Didsettings';
import Ivrsetting from './components/Ivrsetting';
import Ivrreport from './components/Ivrreport';
import IvrfilterReport from './components/IvrfilterReport';
import Ivrprofile from './components/Ivrprofile';
import Error from './components/Error';
import Agentdashboard from './components/Agentdashboard';
import Paytmpay from './components/Paytmpay';
import Apihome from './components/Apihome';
//import { AnimatePresence, motion } from "framer-motion";

function App() {
  

  const [alert, setAlert] = useState(null);
  const showAlert = (message, type)=>{
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
        setAlert(null);
    }, 3000);
}
// const [opennav,setopennav] = useState();
// const toggleClass =() =>{
  
// }

  return (
    
    <div className='mt-1'>
      <NoteState>
      {/* <AnimatePresence exitBeforeEnter> */}
      {/* <motion.div key="888" exit={{ opacity: 0 }} /> */}
        {/* <Router>

                 <Routes>    
                                   
                     <Route exact path="/Service" element={<Service/>} /> 
                </Routes>
       </Router> */}
                <Router>

          {/* <Navbar showAlert={showAlert} /> */}


        
         
        <div className='row'>
        <div className='col-md-9'></div>
        <div className='col-md-3'>
          <Alert alert={alert}/>
          </div>
          </div>
          
          
          
            <Routes>
              
            <Route exact path="/Service" element={<Service/>} /> 
               <Route exact path="/" element={<Login showAlert={showAlert} />} /> 
               <Route exact path="/Did" element={<Didsettings showAlert={showAlert} />} /> 
               <Route exact path="/LiveOBD" element={<Apihome  />} /> 
               
               <Route exact path="/Paytm" element={<Paytmpay />} />
               <Route exact path="/Ivrsetting" element={<Ivrsetting />} />
               <Route exact path="/Ivr" element={<Ivr />} />
               <Route exact path="/Agent" element={<Agentdashboard />} />
               <Route exact path="/Dashboard" element={<Ivrdashboard />} />
               <Route exact path="/Ivragents" element={<Ivragents />} />
               <Route exact path="/Ivrgroups" element={<Ivrgroups />} />
               
               
              <Route exact path="/About" element={<About/>} />
              <Route exact path="/AddCampaign" element={<AddCampaign showAlert={showAlert} />} />
              
              <Route exact path="/Home" element={<Home/>} />
              <Route exact path="/Profile" element={<Profile />} />

              <Route exact path="/IvrUserProfile" element={<Ivrprofile />} />
              

              <Route exact path="/Report" element={<Report/>} />
              <Route exact path="/IvrReport" element={<Ivrreport />} />
              
              <Route  exact path="/login" element={<Login showAlert={showAlert} />} />
              <Route exact path="/signup" element={<Signup/>} />

              <Route exact path="/UploadAudio" element={<UploadAudio showAlert={showAlert} />} />
             
              <Route exact path="/UploadNumber" element={<UploadNumber showAlert={showAlert} />} />

              <Route exact path="/FilterReport" element={<FilterReport showAlert={showAlert} />} />
              <Route exact path="/IvrfilterReport" element={<IvrfilterReport showAlert={showAlert} />} />

              <Route exact path="/AddAgent" element={<AddAgent showAlert={showAlert} />} />

              <Route exact path="/AddUser" element={<AddUser showAlert={showAlert} />} />

              <Route exact path="/ChannelSetting" element={<ChannelSetting showAlert={showAlert} />} />

              <Route exact path="/AddLead" element={<AddLead showAlert={showAlert} />} />

              <Route exact path="/LeadCalllog" element={<LeadCalllog showAlert={showAlert} />} />
              <Route exact path="/UploadLead" element={<UploadLead showAlert={showAlert} />} />
              <Route exact path="/Makegui" element={<Makegui showAlert={showAlert} />} />
              <Route exact path="/Editgui" element={<Editgui showAlert={showAlert} />} />

              <Route exact path="/Crm" element={<Crm showAlert={showAlert} />} />
              <Route exact path="/Error" element={<Error />} />

              <Route path="*" element={<Navigate to ="/Error" />}/> 
              

              


              

              
            </Routes>
            
        </Router>
        {/* </AnimatePresence> */}
      </NoteState>

      </div>
    
  );
}

export default App;
