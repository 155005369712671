import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate,Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import LeadCalllog from './LeadCalllog';
import { CSVLink, CSVDownload } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';



import Swal from 'sweetalert2'
import Userivrnavbar from './Userivrnavbar';




const AddLead = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

     
    const ref = useRef(null)
    const [numberl, setNumberl] = useState();

    const refclicktocalllead = useRef(null)
    const clicktocall =  (e) => {
        refclicktocalllead.current.click();
        optionforagent();
       //alert(e)
       //var num=parseInt(e);
      // setNumberl(num);
      setNumberl(e)
      
       
        
        //alert("clicked>>>>>>>>>");
       
      }


      
    const optionforagent = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/AllivrlistGroupagentByuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('agentopselectmodellead');
        elelist.innerHTML = ""+('<option value="">NA</option>');


        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['agentnumber'] + ' | '+ json[i]['agentname'] +'</option>';
        }
    }

    const[agentid,setAgentid] = useState()



    const [email, setEmail] = useState();
    const [agentnumber, setAgentnumber] = useState();
    const [agentname, setAgentname] = useState();

    const [ecalllogid, seteCalllogid] = useState();
    const [eremarks, seteRemarks] = useState();
    const [ename, setename] = useState();
    const [enumber, setenumber] = useState();
    const [interested, setinterested] = useState();
    
    const clicktocallivr = async(e) => {
        e.preventDefault();
        refclicktocalllead.current.click();
        console.log(">>>>>>>>>>>>>>>>agentid>>>>>>>>>>>>>>>"+agentid)
        console.log(">>>>>>>>>>>>>>>>number>>>>>>>>>>>>>>>>"+numberl)
        var formdata = new FormData()
        formdata.append('agentid', agentid)
        formdata.append('number', numberl)
        const response = await fetch(process.env.REACT_APP_IP+"/User/Clicktocallivr", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    //  'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Success',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 4500
                })
            }
    
            setAgentid("")
            setNumberl("")
    
    
        
       }


    const fireAlert = () => {

        

        Swal.fire('Thanks For Calling', '', 'success');

        // Swal.fire({
        //     title: 'I am Sweet Alert 2.',
        //     showConfirmButton: true,
        //     //showCancelButton: true,
        //     confirmButtonText: "OK",
        //     //cancelButtonText: "Cancel",
        //    // icon: 'warning'
        // }
        // ).then((result) => {
        //     /* Read more about isConfirmed, isDenied below */
        //     if (result.isConfirmed) {
  
        //         Swal.fire('Thanks For Calling', '', 'success');
  
        //     } else
        //         Swal.fire(' Cancelled', '', 'error')
  
        // })
    }
// Modal Popup edit
const updateRemarks = async (e) => {

    ref.current.click();
  //  seteCalllogid("")
   // seteCalllogid(json.id)
    seteRemarks("")
   // seteRemarks(json.remarks)
    setename("")
  //  setename(json.custname)
  //  setenumber(json.custnumber)

    setinterested("")
  //  setinterested(json.interested)
    setEmail("")
  //  setEmail(json.email)
    //alert(e);
    const response = await fetch(process.env.REACT_APP_IP+"/User/getLeadById", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
            "id": e

        })

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
     console.log(json);
    // console.log(json.campname);
    // console.log(json.campaign_mode);
   // alert(json.id)
    //seteCalllogid("")
    seteCalllogid(json.id)
    //seteRemarks()
    seteRemarks(json.remarks)
   // setename("")
    setename(json.custname)
    setenumber(json.custnumber)

   // setinterested("")
    setinterested(json.interested)
  //  setEmail("")
    setEmail(json.email)

}
   
const submiteditform = async (e) => {
    e.preventDefault();

     console.log(ecalllogid + ">>>>>>>" + eremarks + ">>>>>>>>>" + ename + ">>>>>>>>>" + enumber+">>>>>>>>>"+interested)

        var formdata = new FormData()
        formdata.append('id', ecalllogid)
        formdata.append('remarks', eremarks)
        formdata.append('custname', ename)
        formdata.append('custnumber', enumber)
        formdata.append('interested', interested)
        formdata.append('email', email)


        const response = await fetch(process.env.REACT_APP_IP+"/User/EditLeads", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });

        ref.current.click();
        getAllPosts()

}

    const addagentonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        //formData.append('file', uploadFile)
        formData.append('email', email)
        formData.append('name', agentname)
        formData.append('number', agentnumber)

        
        

        const response = await fetch(process.env.REACT_APP_IP+"/User/AddLeaddata", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        if(json.type === "OK")
        {
         props.showAlert("Added  successfully", "success");
 
        }else{
 
         props.showAlert("!!!"+json.msg, "danger");
        }
        getAllPosts()

        



        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }



   





    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/AllLeadsdata?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }

    function callfunction(data){

        alert("called>>>>>"+data)
        
        

    }


    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const clicktocallMethod = async(e) =>{
      //alert("iddddd"+e)
     // e.preventDefault();
      const formData = new FormData()
      //formData.append('file', uploadFile)
      formData.append('id', e)
      //  Swal.fire('Thanks For Calling', '', 'success','3000');
       // Swal.fire('Thanks For Calling', '', 'success');
       // alert("Called")
      //  Swal.fire('Thanks For Calling', '', 'success',3000);
      const response = await fetch(process.env.REACT_APP_IP+"/User/AddLeadcalllogdata", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'multipart/form-data',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formData

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });

       Swal.fire(
           {
            //title: "",

             text: "Thanks For Calling",
            type: "success",
            icon: 'success',
            timer: 3000
           // },
            // function () {
             
            // // alert('closed');
             
            }
            );

            getAllPosts()
        //timer: 3000
    }

    

    useEffect(() => {
        getAllPosts()
    }, [offset])

    const data2 = {
        from: "Link #2",
        message: "Just another message",
        timestamp: Date.now(),
      };



    const getPostData = (data) => {
        return (
            <>
                 
                    

                <div className='row mt-1 '>

                    {/* <AddCampaignform /> */}

                   



                    < div className='col-md-12 col-12' >

                        <div className='card  mt-5' style={{
                            backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)"
                            
                        }}>

                            <div className='card-header text-white'>
                                <h2>Existing Lead</h2>

                               
                                

                                <div className="table-responsive mt-3">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                {localStorage.getItem('roles') === 'ROLE_ADMIN'?<th>User Name</th>:''}
                                                <th>Name</th>
                                                <th>Number</th>
                                                <th>CallLog</th>
                                                <th>Date</th>

                                                <th>Email</th>
                                                <th>Remarks</th>
                                                <th>Edit</th>
                                                <th>Status</th>
                                                <th>Interested</th>
                                               
                                                {/* <th></th> */}


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        {localStorage.getItem('roles') === 'ROLE_ADMIN'?<td>{page.username}</td>:''}
                                                        <td>{page.custname}</td>

                                                        {/* <td>{page.custnumber}
                                                        
                                                        {localStorage.getItem('roles') === 'ROLE_USER'?
                                                        <i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={() => clicktocallMethod(page.id)} ></i>
                                                        :''}
                                                        
                                                        </td> */}

<td>{page.custnumber}
                                                        
                                                        {localStorage.getItem('roles') === 'ROLE_USER'?
                                                        <i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}}  onClick={() => clicktocall(page.custnumber)}  ></i>
                                                        :''}
                                                        
                                                        </td>



                                                       
                                                        
                                                        {/* <td><i className="fa fa-info-circle  btn btn-primary " onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                                                       <td> <Link to="/LeadCalllog" state={ {id: page.id}}>
                                                            <i className="fa fa-info-circle btn btn-primary " aria-hidden="true" ></i>
                                                            
                                                            {/* <LeadCalllog data={page.id} /> */}
                                                            </Link></td>

                                                        <td>{page.leadinsertdate}</td>

                                                        <td>{page.email}</td>
                                                        
                                                        <td> {page.remarks}</td>
                                                        <td>
                                                            
                                                        <button className=" mx-2 btn btn-primary" onClick={() => updateRemarks(page.id)}>
                                                                <i className="far fa-edit" ></i>
                                                            </button>
                                                            </td>
                                                        <td> {page.status}</td>
                                                        <td> {page.interested}</td>
                                                        
                                                            
                                                            {/* <i className="fa fa-edit btn btn-primary "
                                                             style={{marginLeft:"15px"}} onClick={() => updateRemarks(page.id)} >
                                                                 </i>
                                                                 
                                                                 </td> */}
                                                        {/* <td>
                                                           <button className="btn btn-primary" 
                                                                onClick={fireAlert}>
                                                                Click me
                                                            </button>
                                                         </td> */}



                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }


    return (

       <>
       <Userivrnavbar />
       
   


        <div className='row mt-4 mx-1'>
         

         {/*  click to call Model*/}
 <button ref={refclicktocalllead} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModalLabellead">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModalLabellead" tabIndex="-1" aria-labelledby="exampleModalLabellead" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabellead">Call Lead Data</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          
                            <form className=" p-2" onSubmit={clicktocallivr}>

                               <label className="mb-2" htmlFor="number">Select Agent :</label>
                                <select id="agentopselectmodellead" className="form-select mb-4" onChange={(e) => setAgentid(e.target.value)}
                                        required>
                                        {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                                <option value=""></option> */}


                                    </select>  
                               
                              
                                
                         <div className=" mb-2 ">
                            <label className=" mb-1" htmlFor="number">Number:</label>
                              <input type="text" className="form-control" id="numberl" placeholder="Enter number to Call"
                                  name="numberl" value={numberl}
                                  onChange={(e) => setNumberl(e.target.value)} required />
                              
                          </div>
                         

                           
                             
                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="CALL" />
                                </div>

                            </form>
                        </div>
                        
                    </div>
                </div>
                </div>

      











<button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch  modal
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Remarks</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className=" p-2" onSubmit={submiteditform}>
                                <div className="mb-2">

                                    <input type="text" className="form-control" id="ecalllogid" name="ecalllogid" value={ecalllogid}
                                        onChange={(e) => seteCalllogid(e.target.value)} required={true} readOnly={true} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Remarks</label>
                                    <input type="text" className="form-control" id="eremarks" name="eremarks" value={eremarks}
                                        onChange={(e) => seteRemarks(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="ename" name="ename" value={ename}
                                        onChange={(e) => setename(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Number</label>
                                    <input type="text" className="form-control" id="enumber" name="enumber" value={enumber}
                                        onChange={(e) => setenumber(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" name="email" value={email}
                                        onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                               
                                <div className="mb-2">
                                    <label htmlFor="interested" className="form-label">Interest</label>
                                    <select className="form-select" name="interested" value={interested}
                                        onChange={(e) => setinterested(e.target.value)} >
                                        <option value="">Select Interest</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>
                                    </select>
                                    
                                </div>
                               





                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                            {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                        </div>
                    </div>
                </div>
            </div>


            <div className='col-md-3 col-12 text-white'>


<form className='card p-3' style={{
    backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)"
    , minHeight: "400px", marginTop: "50px"
}} onSubmit={addagentonsubmit}>
    <h4>Add Lead</h4>
   

    <div className="mb-3">
        <label htmlFor="agentname" className="form-label"> Name</label>
        <input name="agentname" type="text" className=" form-control form-control-sm"
            onChange={(e) => setAgentname(e.target.value)} required />

        
    </div>

    <div className="mb-3">
        <label htmlFor="agentnumber" className="form-label"> Number</label>
        <input name="agentnumber" type="text" className=" form-control form-control-sm"
            onChange={(e) => setAgentnumber(e.target.value)} required />

        
    </div>

    <div className="mb-3">
        <label htmlFor="email" className="form-label"> Email</label>
        <input name="email" type="text" className=" form-control form-control-sm"
            onChange={(e) => setEmail(e.target.value)}  />

        
    </div>


             <input className="btn btn-primary mt-3" type="submit" value="Submit" />
  

 
    

    
</form>

</div>


            <div className="col-md-9 col-12" >

                {/* Display all the posts */}
                {posts}

                <div className="row">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12 mb-5">


                        <ReactPaginate
                            previousLabel={<i className="fa-solid fa-angles-left"></i>}
                            nextLabel={<i className="fa-solid fa-angles-right"></i>}
                            breakLabel={".."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>




        </div>
        </>
    )


}


export default AddLead
