import React from 'react'
import { useEffect, useState ,useRef} from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';


import axios from 'axios'

import ReactAudioPlayer from 'react-audio-player';
import { AnimatePresence, motion } from "framer-motion";
import Navbar from './Navbar';
import Userivrnavbar from './Userivrnavbar';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from 'sweetalert2';


const Ivrreport = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])

  useEffect(() => {
    document.title = "Ivr Calllog"
 }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 1
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 1.2
    }
  };
  
  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 1.0
  };
  
  const pageStyle = {
    // position: "absolute"
  };

  


  const headers = [
    { label: "Caller", key: "CustomerNumber" },
    { label: "Source", key: "did" },
    { label: "Calltype", key: "type" },
    { label: "Agents Name", key: "agentname" },
    { label: "Agents number", key: "AgentNumber" },
    { label: "Start", key: "dialtime" },
    { label: "End", key: "agenthanguptime" },
    { label: "Duration", key: "totalduration" },
    { label: "Keypress", key: "pressedkey" },
    { label: "Dial Status", key: "dialstatus" }


  ];
  const[agentid,setAgentid] = useState()
  const [number, setNumber] = useState();

  const refclicktocallrep = useRef(null)


    const clicktocall = async (e) => {
      setNumber(e)
      
      refclicktocallrep.current.click();
      //alert(">>"+e)

     
     
        
        optionforagent();
        //alert("clicked>>>>>>>>>");
       
      }


      const optionforagent = async (e) => {

        

        const respp = await fetch(process.env.REACT_APP_IP+"/User/AllivrlistGroupagentByuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('agentopselectmodelrep');
        elelist.innerHTML = ""+('<option value="">NA</option>');


        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['agentnumber'] + ' | '+ json[i]['agentname'] +'</option>';
        }


        
        
    }




    const clicktocallivrrep = async(e) => {
      e.preventDefault();
      refclicktocallrep.current.click();
      console.log(">>>>>>>>>>>>>>>>agentid>>>>>>>>>>>>>>>"+agentid)
      console.log(">>>>>>>>>>>>>>>>number>>>>>>>>>>>>>>>>"+number)
      var formdata = new FormData()
      formdata.append('agentid', agentid)
      formdata.append('number', number)
      const response = await fetch(process.env.REACT_APP_IP+"/User/Clicktocallivr", {
              method: 'POST',
              // headers: {
              //     'Content-Type': 'application/json'
              // },
  
              headers: {
                  //  'Content-Type': 'application/json',
  
                  "Authorization": `Bearer ${localStorage.getItem('token')}`
              },
              body: formdata
              // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
          });
          const json = await response.json()
          console.log(json);
          if (json.type === "OK") {
              // props.showAlert(json.msg, "success");
              Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Success',
                  background: '#e9ecef',
                  // title: 'Done Successfully ',
                  //background: primary,
                  text: json.msg,
                  showConfirmButton: false,
                  timer: 2500
              })
          } else {
              //props.showAlert(json.msg, "danger");
              Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'error',
                  background: '#e9ecef',
                  // title: 'Done Successfully ',
                  //background: primary,
                  text: json.msg,
                  showConfirmButton: false,
                  timer: 4500
              })
          }
  
          setAgentid("")
          setNumber("")
  
  
      
     }

  const getdata = async () => {
    const res = await axios.get(process.env.REACT_APP_IP + `/User/AllReportdownload`,
      { 
        headers: 
        { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem('token')}` 
        } 
      }
      )

      // console.log(">>>>>>>>>>>rec>>>>>>>>"+process.env.REACT_APP_REC_LINK)
      // console.log(">>>>>>>>>>>rec>>>>>>>>"+process.env.REACT_APP_IP)
      

    //const datakk = res.data;

   // const json = await res.data

    //alert(JSON.stringify(res.data))

    //return JSON.stringify(res.data);
    setreportdaat(res.data)


  }
  
  //const data = reportdaat;

  useEffect(() => {
    getdata();
  }, [])
  
 
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);

  const [reportdaat, setreportdaat] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
     
       
     <tbody>
                {data.content.map((page,index) => {
                  return (
                    <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                      <td>{((offset -1)  * 10) + index + 1}</td>
                      <td>{page.CustomerNumber}</td>
                      <td>{page.did}</td>
                      <td>{page.type}</td>
                      <td>{page.agentname}  {page.AgentNumber}</td>
                      <td >{page.dialtime}</td>
                      <td>{page.agenthanguptime}</td>
                      <td>{page.totalduration}</td>
                      <td>{page.pressedkey}</td>
                      <td>{page.dialstatus}</td>
                      {/* <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                     
                      <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}}  onClick={(e) => clicktocall(page.CustomerNumber)}  ></i></td> 
                      
                      <td>
                          <ReactAudioPlayer 
                                    src={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname}
                              controls />
                      </td>

                      <td></td>
                      <td></td>
                      <td><i className="fa fa-comment-o fa-xl btn btn-success " style={{marginLeft:"15px"}}  ></i></td>
                      <td><i className="fa fa-arrow-circle-o-up fa-2xl btn btn-info" style={{marginLeft:"15px"}}  ></i></td>


                    </tr>
                  );
                })}
              </tbody>
       
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllReport?pageNumber=${offset - 1}&&
    size=10`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts();
  }, [offset])



  return (
  <>
   <Userivrnavbar />
 
    <div className='row mt-5 p-3 mybgcolor'>
       
        <div className='row'>
          <div className='col-md-12'>
 
            <div className="table-responsive">
              <h1 className='text-center mt-2 card-header '>Report</h1>


              {/* Display all the posts */}
              <div className=' '>
       
       <div className=" table-responsive p-2">
       <motion.div
 // style={pageStyle}
 // initial={{ x: 300, opacity: 0 }}
 // animate={{ x: 0, opacity: 1,rotate: 360}}
 // exit={{ rotate: 360}}
 // variants={pageVariants}
 // transition={pageTransition}
 // initial={{ scale: 0 }}
 // // animate={{ scale: 1 }}
 // animate={{
 //   scale: [1, 2, 2, 1, 1],
 //   rotate: [0, 0, 270, 270, 0],
 //   borderRadius: ["20%", "20%", "50%", "50%", "20%"],
 // }}
 // transition={{ duration: 1.5 }}


   //animate={{ rotate: [0,1,1, 0] }}
            // transition={{ type: "spring", duration: 5, bounce: 0.6 }}
            initial={{ x: -1200 }}

            animate={{ x: 0 }}
            transition={{ duration: 0.5}}
           // transition={{ type: "spring",bounce: 0.55,duration: 1.1}}

           // transition={{ type: "spring", duration: 5, bounce: 0.6 }}

>
         <table className="table table-hover table-bordered mytablecss">
           <thead className="bg-darkblue text-white">
             <tr>
               <th>ID</th>

               <th>Caller</th>
               <th>Source</th>
               <th>Calltype</th>
               <th>Agents</th>
               <th>Start</th>
               <th>End</th>
               <th>Duration</th>
               <th>Keypress</th>
               <th>Status</th>
               <th>Action</th>
               <th>Recording</th>
               <th>Circle</th>
               <th>Operator</th>
               <th>Remarks</th>
               <th>SMS Suite</th>
               

             </tr>
           </thead>
           {posts}
         </table>

         Goto Pageno:<input type="text" className="col-md-1 mx-2 mb-2" id="gotopage" aria-describedby="gotopage"
           placeholder="gotopage" onChange={onInputChange} />

</motion.div>
         {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
       </div>

       
      
     </div>
            

              {/* Using React Paginate */}
             
              <CSVLink className="btn btn-success mt-3" data={reportdaat} headers={headers} filename={"Callreport.csv"} target="_blank"  ><i className="fa-solid fa-download"></i> Download Report</CSVLink>
          




   


            </div>
            <div className='row mb-5'>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <ReactPaginate
                  previousLabel={<i className="fa-solid fa-angles-left text-black"></i>}
                  nextLabel={<i className="fa-solid fa-angles-right text-black"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  breakLinkClassName={"break-mef"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={offset - 1}

                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />

              </div>
              <div className='col-md-4'></div>
            </div>


          </div>


        </div>


        {/*  click to call Model*/}
   <button ref={refclicktocallrep} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModalclicktocallc2c">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModalclicktocallc2c" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Click To Call</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          
                            <form className=" p-2" onSubmit={clicktocallivrrep}
                             >

                               <label className="mb-2" htmlFor="number">Select Agent :</label>
                                <select id="agentopselectmodelrep" className="form-select mb-4" 
                                   onChange={(e) => setAgentid(e.target.value)}
                                        required>
                                        {/* <option value={euserid}>{euserid} | {eusername}</option>
                                                                <option value=""></option> */}


                                    </select>  
                               
                              
                                
                         <div className=" mb-2 ">
                            <label className=" mb-1" htmlFor="number">Number:</label>
                              <input type="text" className="form-control" id="numberjj" placeholder="Enter number to Call"
                                  name="number" 
                                   value={number}
                                  onChange={(e) => setNumber(e.target.value)} 
                                  
                                  required />
                              
                          </div>
                         

                           
                             
                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="CALL" />
                                </div>

                            </form>
                        </div>
                        
                    </div>
                </div>
                </div>


     
       
      </div>

      </>
    
  )
}

export default Ivrreport
