//import '../static/default.css';
//import '../static/theme.css';

import '../login.css'
import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';



const Login = (props) => {
    let history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
            if (localStorage.getItem('roles') === 'ROLE_USER') {
                history('/Service');
                }else if (localStorage.getItem('roles') === 'ROLE_APIOBDUSER') {
                    history('/LiveOBD');
                    }else{
                  history('/Home');
  
                }
        } else {
            history('/login');
        }
    }, [])

    useEffect(() => {
        document.title = "Login"
     }, []);

    // const { showAlert } = props
    // const [alert, setAlert] = useState(null);
    // const showAlert = (message, type)=>{
    //     setAlert({
    //       msg: message,
    //       type: type
    //     })
    //     setTimeout(() => {
    //         setAlert(null);
    //     }, 2500);
    // }



    const [credentials, setCredentials] = useState({ username: "", password: "" })
    // let history = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(process.env.REACT_APP_IP+"/authenticate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: credentials.username, password: credentials.password })
        });
        const json = await response.json()
        // console.log(json.token.length>10);
        //alert(">>>>"+json);
        if (json.status === 401) {

            // alert("Invalid credentials");
            props.showAlert("Invalid credentials", "danger");
        } else {

            if (json.token.length > 10) {
                // Save the auth token and redirect
                localStorage.setItem('token', json.token);
                localStorage.setItem('roles', json.roles);

                // history.push("/");
              //  history('/Home');
              if (localStorage.getItem('roles') === 'ROLE_USER') {
              history('/Service');
              }else if (localStorage.getItem('roles') === 'ROLE_APIOBDUSER') {
                history('/LiveOBD');
                }else{
                history('/Home');

              }
              
              //  props.showAlert("Successfully Login", "success");
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: "Successfully login",
                background: '#e9ecef',
               // title: 'Done Successfully ',
               //background: primary,
                showConfirmButton: false,
                timer: 1500
              })


            } else {
                props.showAlert("Invalid credentials", "danger");
            }
        }

    }

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    return (
        <>

        <div className='snippet-body'>
<div className=" mx-1  mt-3 mx-auto">
     <div className="cardlogin card0 border-0">
        <div className="row d-flex">
            <div className="col-lg-6">
                <div className="card1 mb-5">
                    <div className="row">
                        <img src={require('../images/logo.png')} alt="null"  className="logo bg-primary mx-4"/>
                    </div>
                    <div className="row mx-3 justify-content-center mt-4 mb-5 border-line">
                        <img src="https://i.imgur.com/uNGdWHi.png" className="image"/>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="card2 cardlogin border-0 mx-4 p-5">
            <form onSubmit={handleSubmit}>
                   <div className="row ">
                    
                        <h6 className="col-md-3 mb-0 mx-1 mt-2">Sign in with</h6>
                        <div className="facebook text-center mx-3"><div className="fa fa-facebook"></div></div>
                        <div className="twitter text-center mx-3"><div className="fa fa-twitter"></div></div>
                        <div className="linkedin text-center mx-3"><div className="fa fa-linkedin"></div></div>
                    
                    </div>
                    <div className="row px-3 mb-4">
                        <div className="line"></div>
                        <small className="or text-center">Or</small>
                        <div className="line"></div>
                    </div>
                    <div className="row px-3">
                        <label className="mb-1"><h6 className="mb-0 text-sm">Email Address</h6></label>
                        
                        <input name="username" type="text" value={credentials.username} onChange={onChange} className="form-control input-lg mb-4" placeholder="Username" autoFocus required />

                    </div>
                    <div className="row px-3">
                        <label className="mb-1"><h6 className="mb-0 text-sm">Password</h6></label>
                        <input name="password" type="password" value={credentials.password} onChange={onChange} className="form-control input-lg mb-4" placeholder="Password" required />

                    </div>
                    <div className="row mb-4">
                        <div className="custom-control custom-checkbox custom-control-inline">
                            <input id="chk1" type="checkbox" name="chk" className="custom-control-input col-md-2 col-2"/> 
                            <label htmlFor="chk1" className="custom-control-label text-sm col-md-4 col-4">Remember me</label>
                            <a href="#" className=" float-end col-md-3 text-black ">Forgot Password?</a>
                        </div>
                        
                    </div>
                    <div className="row mb-3 px-3">
                        <button type="submit" className="btn btn-blue text-center">Login</button>
                    </div>
                    <div className="row mb-4 px-3 font-weight-bold">
                        <small className="">Don't have an account? <a className="text-danger ">Register</a></small>
                    </div>
                </form>
            </div>
            </div>
        </div>
        <div className="bg-blue p-4">
            <div className="row  ">
                <small className=" col-md-4 ml-sm-5 mb-2">Copyright &copy; 2022. All rights reserved.</small>
                <div className="social-contact col-md-8">
                    <span className="fa fa-facebook mx-3 text-sm float-end"></span>
                    <span className="fa fa-google-plus mx-3 text-sm float-end"></span>
                    <span className="fa fa-linkedin mx-3 text-sm float-end"></span>
                    <span className="fa fa-twitter mx-3 mr-sm-5 text-sm float-end"></span>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
        </>
    )
}

export default Login
