import {useEffect,React} from 'react'
import { useNavigate } from "react-router-dom";
import OrgDiagram from 'basicprimitivesreact/dist/umd/OrgDiagram';
import { PageFitMode, Enabled } from 'basicprimitives';
import Userivrnavbar from './Userivrnavbar';

const Ivr = () => {
  let history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  })

    var photos = {
        // a: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAA8CAIAAACrV36WAAAAAXNSR0IArs4c6QAAAARn' + 
        // 'QU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGnSURBVGhD7dnBbQJBDAVQk1o2QjlQwKYGzpSwKQfq4IxIC' + 
        // 'RTB9jLZHCJFwWv7/7EiDt6zmX2yPYMHNq01eb7n5flI36JiIXWpbFW2kAwgsdVblS0kA0hs9db/ZWs+vW/Wno9PxPE3dh' + 
        // 'ls6Od+HI1XT1d64Sb8R5utEulwdbA8VY+LZ/kqkfF456pBHxDz5Xxze/p2vsxukBbAshTVOE0PO4B2cUlWKrgUTKsrV0e' + 
        // 'ut3RVU/cm5aKKqPXVbjuIDPtDUh2JImq1+jmjkupIFNFStXadHncWXkecpb3393me4oJZnionXyjLV6W4QFZEleHCWNG+' + 
        // '0eKggQJiRVV6vhAXwoqrul0AC1H1uuIsTLUyukYH1jBL7WJ8lgq6oqwkVXSQDrLSVEFXjJWoirlCrFRVyBVhJasirgCr6' + 
        // '5tEv7a5A5jL0tcN7vNl9OVcHqtXRbocVr+Kc9k3H/3qPL69Ise7dh0SsS+2JmtFddgvdy/gGbY7Jdp2GRcyrlu1BfUjxt' + 
        // 'iPRm/lqVbGHOMHnU39zQm0I/UbBLA+GVosJHGVrcoWkgEktnoLydYXkF/LiXG21MwAAAAASUVORK5CYII=',

        a:'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg',

        b:'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg'
      };
     // var bphoto

     useEffect(() => {
      document.title = "Ivr"
   }, []);

    const config = {
        pageFitMode: PageFitMode.AutoSize,
       //pageFitMode: PageFitMode.None,
        autoSizeMinimum: { width: 100, height: 100 },
        //cursorItem: 0,
      //  highlightItem: 0,
        linesWidth: 5,
      linesColor: "blue",
      hasSelectorCheckbox: Enabled.True,
    //   normalLevelShift: 20,
    //   dotLevelShift: 20,
    //   lineLevelShift: 20,
    //   normalItemsInterval: 10,
    //   dotItemsInterval: 30,
    //   lineItemsInterval: 30,
     
      showExtraArrows: false,
        hasSelectorCheckbox: Enabled.True,
        items: [
          {
            id: 0,
            parent: null,
            title: 'First Level',
            description: '120718900',
            image: photos.b
          },
          {
            id: 1,
            parent: 0,
            title: 'Second Level',
            description: '0,Route',
            image: photos.a
          },
          {
            id: 2,
            parent: 0,
            title: 'Second Level',
            description: '1,Route',
            image: photos.a
          },
          {
            id: 3,
            parent: 0,
            title: 'Second Level',
            description: '2,Route',
            image: photos.a
          },
          {
            id: 5,
            parent: 0,
            title: 'Second Level',
            description: '3,Route',
            image: photos.a
          },
          {
            id: 6,
            parent: 0,
            title: 'Second Level',
            description: '4,Route',
            image: photos.a
          }
          ,
          {
            id: 4,
            parent: 3,
            title: 'Third Level',
            description: '0,Route',
            image: photos.a
          }
        ]
      };
     
      return (
        <>
        <Userivrnavbar />
        <div style={{ marginTop: "75px" }}>

          <div className='row'>
              <div className='col-md-2'>



              </div>
              <div className='col-md-8'>

              <OrgDiagram centerOnCursor={true} config={config} />
                
              </div>
              <div className='col-md-2'>


                
              </div>

          </div>
                    
                     
                    
        </div>


        </>
      );

      
    }


export default Ivr