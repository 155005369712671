import React from 'react'
import { useEffect,useState } from 'react'
import Navbar from './Navbar';
import profileimg from '../images/profile.jpg'

const Profile = () => {
   // const [userinfodata, Setuserinfo] = useState();

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [did, setDid] = useState();
    const [addr, setAddr] = useState();
    const [mob, setMob] = useState();
    const [voicecredit, setvoicecredit] = useState();
    const [voicecreditconsume, setvoicecreditconsume] = useState();

    const userinfo = async (e) => {
        setName('')
        setAddr('')
        setEmail('')
        setDid('')
        setMob('')
        setvoicecredit('')
        setvoicecreditconsume('')
        const respp = await fetch(process.env.REACT_APP_IP+"/User/GetUserinfo", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
      
            headers: {
                'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
       // alert(JSON.stringify(json))
       // Setuserinfoarray(json)
       // Setuserinfo(json.username)
       // console.log(json.length)
       setName(json.username)
       setAddr(json.userpro.address)
       setEmail(json.userpro.email)
       setDid(json.userpro.did)
       setMob(json.userpro.mobile)
       setvoicecredit(json.userpro.voicecredit)
       setvoicecreditconsume(json.userpro.voicecreditconsume)

       
      }
      
      useEffect(() => {
        userinfo()
      }, [])
  return (
    <>
    <Navbar  />
    <div className=" rounded bg-white mt-5 mb-5 ">
    <div className="row mt-5">
        <div className="col-md-3 border-right mt-1 p-3">
            <div className="d-flex flex-column align-items-center text-center text-white p-3 py-5">
                <img className="rounded-circle mt-5" width="150px" src={profileimg}/>
                {/* <span className="font-weight-bold">{userinfodata}</span>
                <span className="text-white-50">{userinfodata}</span> */}
                <span> </span>
            </div>
        </div>
        <div className="col-md-9 border-right">
            <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-right">Profile </h4>
                </div>
               
                <div className="row mt-3">
                              <div className="card mb-3">
                                  <div className="card-body">
                                      <div className="row">
                                          <div className="col-sm-3">
                                              <h6 className="mb-0">Name</h6>
                                          </div>
                                          <div className="col-sm-9 text-secondary">
                                             {name} 
                                             
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-sm-3">
                                              <h6 className="mb-0">Email</h6>
                                          </div>
                                          <div className="col-sm-9 text-secondary">
                                         {email}   
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-sm-3">
                                              <h6 className="mb-0">Did</h6>
                                          </div>
                                          <div className="col-sm-9 text-secondary">
                                              {did}
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-sm-3">
                                              <h6 className="mb-0">Address</h6>
                                          </div>
                                          <div className="col-sm-9 text-secondary">
                                             {addr}
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-sm-3">
                                              <h6 className="mb-0">Mobile Number</h6>
                                          </div>
                                          <div className="col-sm-9 text-secondary">
                                              {mob}
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-sm-3">
                                              <h6 className="mb-0">Voice Credit</h6>
                                          </div>
                                          <div className="col-sm-9 text-secondary">
                                              {voicecredit}
                                          </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                          <div className="col-sm-3">
                                              <h6 className="mb-0">Voice Credit Consume</h6>
                                          </div>
                                          <div className="col-sm-9 text-secondary">
                                              {voicecreditconsume}
                                          </div>
                                      </div>
                                      
                                  </div>
                              </div>
                </div>
               
               
              
            
            </div>
        </div>
       
    </div>

    </div>
    
    
    
    
    
    
    
    </>
  )
}

export default Profile