
//import formJSON from '../formElement.json';
import { useState, useEffect,useRef } from 'react';
import Element from './Element';
import { FormContext } from '../FormContext';
import $ from 'jquery';
// import { Button } from 'bootstrap';
import { useTime } from 'react-timer-hook';

function Crm() {
  // console.log(">>>>>>>out>>>>>>>"

  const ref = useRef(null)
  const refconf = useRef(null)

  const refclicktocall = useRef(null)

  
  
  // const [busyflag, setBusyflag] = useState();

  const transferAgent = async () => {

    ref.current.click();
    //alert("clicked>>>>>>>>>");
   
}



const clicktocall = async () => {

  refclicktocall.current.click();
  //alert("clicked>>>>>>>>>");
 
}

const confAgent = async () => {

  refconf.current.click();
  //alert("clicked>>>>>>>>>");
 
}



const [agentname, setAgentname] = useState();
  const [number, setNumber] = useState();
  const [remark, setRemarks] = useState();
  const [busyflagnew, setBusyflagnew] = useState();
  const [elements, setElements] = useState(null);
  const [userstatus, setuserstatus] = useState(null);
  //  console.log(">>>>>>>true>>>>"+busyflag);
  var busyflag = true;
  useEffect(() => {

    // setBusyflag(true);
    const timer = setInterval(async () => {
      const userinfo = await fetch("http://localhost:8080/User/GetUserinfo", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
          'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      })
      const json = await userinfo.json();




      // setuserstatus(json.userpro.status);

      console.log(">>>>>>>>>userstatus>>>>>>>" + userstatus + ">>>>>>>>>>>" + json.userpro.status)


      if (json.userpro.status === "BUSY") {
        const response = await fetch("http://localhost:8080/User/GetCampaignField", {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
        });
        const json2 = await response.json()


        if (busyflag) {

          setElements(json2);
          busyflag = false;
        }




        console.log(">>>>>>>>>if called>>>>>>>>" + busyflag)
      }
      if (json.userpro.status === "FREE") {
        busyflag = true;
        //setuserstatus(json.userpro.status);
      }

      // }else{
      //   console.log(">>>>>>>>>else called>>>>>>>>"+busyflag) 
      // }

      return () => clearInterval(timer);
    }, 5000)


  }, [])

 




  const { fields, page_label } = elements ?? {}



  const handleSubmit = async (event) => {
    event.preventDefault();
    busyflag = true;
    console.log("busyflag>>>>>>>>>" + busyflag)
    //setuserstatus("");
    setElements(null);
    setRemarks('');
    //const elll=JSON.stringify(elements)

    //console.log("lllll"+JSON.stringify(elements))

    const result = elements.fields.map(item => {
      return {
        //id: parseInt(item.id),
        id: item.id,

        fieldvalue: item.fieldvalue


      };
    });



    console.log(result);

    // let payload = {
    //   fields: elements
    // };


    const response = await fetch("http://localhost:8080/User/SubmitCrmdata", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(result)
      // body: payload
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    // setBusyflag(true);

    // const resulth = elements.fields.map(item => {
    //   return {
    //     //id: parseInt(item.id),
    //     id: item.id,

    //     fieldvalue: ""


    //   };
    // });
    //setElements(null)

    // setBusy(true)
    //console.log("evvvvvv"+event)
  }



  const handleChange = (id, event) => {
    const newElements = { ...elements }
    newElements.fields.forEach(field => {
      const { fieldtype, fieldid } = field;
      if (id === fieldid) {
        switch (fieldtype) {
          case 'DROPDOWN':
            // alert("ok");
            field['fieldvalue'] = event.target.value;
            break;

          default:
            field['fieldvalue'] = event.target.value;
            break;
        }


      }
      setElements(newElements)
    });
    console.log(elements)
  }

  const {
    seconds,
    minutes,
    hours,
    ampm,
  } = useTime({ format: '24-hour' });

  const handleChangetxtarea = (event) => {
    setRemarks(event.target.value)
  }


  return (
    <>






      <div style={{ marginTop: "65px" }}>
        <FormContext.Provider value={{ handleChange }}>
          <div className="">

            <h3>{page_label}</h3>
              <div className='card'>
                <div className='row p-3'>
                      <div className='col-md-6 mb-2'>
                            <button className='btn btn-success rounded btn-sm mx-1' onClick={(e) => handleSubmit(e)}><i className="fas fa-save mx-1"></i>Save</button>
                            <button className='btn btn-primary rounded btn-sm mx-1' onClick={() => transferAgent()}>Trans</button>
                            <button className='btn btn-primary rounded btn-sm mx-1' onClick={() => confAgent()} >Conf</button>
                            <button className='btn btn-danger rounded btn-sm mx-1'><i className="fa fa-phone-slash mx-1"></i>Hang</button>

                      </div>
                     
                    <div className='col-md-4'>

                    </div>
                      <div className='col-md-2 float-right bg-primary text-white d-flex align-items-center float-right'>

                              <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>

                      </div>
                </div>
                </div>
            <form className='mybgcolor'>
              <div className='row p-3'>

                {fields ? fields.map((field, i) => <Element key={i} field={field} />) : null}


                <div className='row mt-4'>
            
                <div className='col-md-1'>
                  
                </div>
             
                <div className='col-md-9'>
                  <label>Remarks:</label>
                  <textarea className='form-control' value={remark} onChange={handleChangetxtarea} />

                </div>
              </div>
              </div>
              {/* <button type="submit" className="btn btn-primary mt-2 mb-5 text-center" onClick={(e) => handleSubmit(e)}>Submit</button> */}

            </form>

          </div>
        </FormContext.Provider>

       

      {/* </div> */}
      <button ref={refconf} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Conference</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* <form className=" p-2" onSubmit={submiteditform}> */}
                            <form className=" p-2">
                            <div className=" mb-2 ">
                            <label className=" mb-1" htmlFor="number">Number:</label>
                              <input type="text" className="form-control" id="number" placeholder="Enter number to transfer"
                                  name="number" value={number}
                                  onChange={(e) => setNumber(e.target.value)} required />
                              
                          </div>
                          <div className=" mb-2 ">

                                 <div className='bg-info mt-3'>
                                         ------------ OR-------------
                                 </div> 

                          </div>

                          <div className="mb-2">
                                     <label  className="form-label">Select Agent:</label>
                                          <select id="agentfortrans" className="form-select" name="agentname"
                                              value={agentname}
                                              onChange={(e) => setAgentname(e.target.value)} required>
                                              <option value="">-- Select --</option>
                                              <option value="NA">NA</option>

                                          </select>
                            </div> 
                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="ADD IN Conference" />
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                            {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                        </div>
                    </div>
                </div>
            </div>

      <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Transfer</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* <form className=" p-2" onSubmit={submiteditform}> */}
                            <form className=" p-2">
                               
                               
                              
                                
                            <div className=" mb-2 ">
                            <label className=" mb-1" htmlFor="number">Number:</label>
                              <input type="text" className="form-control" id="number" placeholder="Enter number to transfer"
                                  name="number" value={number}
                                  onChange={(e) => setNumber(e.target.value)} required />
                              
                          </div>
                          <div className=" mb-2 ">

                                 <div className='bg-info mt-3'>
                                         ------------ OR-------------
                                 </div> 

                          </div>

                          <div className="mb-2">
                                     <label  className="form-label">Select Agent:</label>
                                          <select id="agentfortrans" className="form-select" name="agentname"
                                              value={agentname}
                                              onChange={(e) => setAgentname(e.target.value)} required>
                                              <option value="">-- Select --</option>
                                              <option value="NA">NA</option>

                                          </select>
                            </div>  
                                {/* <div className="mb-2">
                                    <label  className="form-label">Select Audio:</label>
                                    <select id="sel" className="form-select" name="audio"
                                        value={eselectaudio}
                                        onChange={(e) => setEeselectaudio(e.target.value)} required>
                                        <option value="">-- Select --</option>
                                        <option value="NA">NA</option>

                                    </select>
                                </div> */}


                                {/* <div className="mb-2">
                                    <label  className="form-label">Select List:</label>
                                    <select id="sellist" className="form-select" name="listname"
                                        value={eselectlist}
                                        onChange={(e) => setEeselectlist(e.target.value)} required>
                                        <option value="">-- Select List--</option>
                                        <option value="NA">NA</option>

                                    </select>
                                </div> */}




                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="Transfer" />
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                            {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                        </div>
                    </div>
                </div>
                </div>

              {/*  click to call Model*/}
              <button ref={refclicktocall} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Click To Call</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          
                            <form className=" p-2">
                               
                               
                              
                                
                            <div className=" mb-2 ">
                            <label className=" mb-1" htmlFor="number">Number:</label>
                              <input type="text" className="form-control" id="number" placeholder="Enter number to Call"
                                  name="number" value={number}
                                  onChange={(e) => setNumber(e.target.value)} required />
                              
                          </div>
                         

                           
                             
                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="CALL" />
                                </div>

                            </form>
                        </div>
                        
                    </div>
                </div>
                </div>


            
            <div className="slide-out-div">

							{/* <img className="mx-auto d-block" style="filter: invert(1);"
								th:src="@{/images/c2cn.png}"/> */}

             <img className="mx-auto d-block ml-1"  src={require('../images/c2cn.png')} alt="null" onClick={(e) => clicktocall(e)}   />

						</div>

            </div>
      
    </>
  );


}

export default Crm;
