// import '../static/default.css';
import React from 'react'
import { useEffect,useState } from 'react'
import '../index.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { Nav, NavDropdown } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import Login from './Login';
import Swal from 'sweetalert2';
//import About from './About';


const Navbar = (props) => {

    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/login');
        }
    }, [])

    console.log(">>>>>>>>>>>>>"+localStorage.getItem('roles'))

   // const[userinfo,Setuserinfo]
    const [userinfodata, Setuserinfo] = useState();

    const userinfo = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/GetUserinfo", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
      
            headers: {
                'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        Setuserinfo(json.username)
       // console.log(json.length)
       
      }
      
      useEffect(() => {
        if (localStorage.getItem('token')) {
        userinfo()
        }
      }, [])


    const [opennav, setopennav] = useState(true);
    const toggleClass = () => {
        //alert("clicked");
        if (!opennav) {
            setopennav(true);
        } else {
            setopennav(false);
        }
        // alert("clicked"+opennav);

    }


    const { showAlert } = props
    let history = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        history('/login');
       // props.showAlert("Successfully Logout", "success");
       Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: "Successfully Logout",
        background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

    let location = useLocation();
    return (
        <>

            {/* <h1>Agent</h1> */}
            {/* <About data={opennav.data} />  */}






            {!localStorage.getItem('token') ?
                <div className="" >



                </div> :
                <div className="" >

                    {(() => {
                        if (localStorage.getItem('roles') === 'ROLE_USER') {
                            return (

                                <>
                                    <div>

                                        {/* <div className="sidenav"> */}
                                        <nav className="navbar fixed-top navbar-expand-lg navbar-dark mybg-primary">
                                            <div className="container-fluid">



                                                <Link className="navbar-brand" to="/Agent"><i className="fa fa-home mx-1" aria-hidden="true"></i>Home</Link>
                                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>

                                                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Home">
                                                                <i className="fa fa-home mr-15" aria-hidden="true"></i>Home</Link>
                                                        </li> */}


                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link " to="/">
                                                                <i className="fa fa-user-o mr-15" aria-hidden="true"></i>User</Link>

                                                        </li> */}

                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa fa-table mr-15" aria-hidden="true"></i> Report
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item p-2" to="/Report">
                                                                    Report</Link>

                                                                <Link className="dropdown-item  p-2" to="/FilterReport" >Filter Report</Link>
                                                                <div className="dropdown-divider"></div>



                                                            </div>


                                                        </li>

                                                        {/* <Link className={`mynavlink ${location.pathname === "/about" ? "active" : ""}`} to="/about">
                                            
                                        <i className="fa fa-info-circle mr-15" aria-hidden="true"></i>About</Link> */}

                                                         <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa-solid fa-bullhorn mr-15"></i> Campaign
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item  p-2" to="/AddCampaign" >Add Campaign</Link>
                                                                <div className="dropdown-divider"></div>
                                                                {/* <Link className="dropdown-item p-2" to="/CallLog" >CallLog</Link>
                                                                <div className="dropdown-divider"></div> */}

                                                            </div>
                                                        </li> 
                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa fa-upload mr-15" aria-hidden="true"></i> Store
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item  p-2" to="/UploadAudio" >Upload Audio</Link>
                                                                <div className="dropdown-divider"></div>
                                                                <Link className="dropdown-item p-2" to="/UploadNumber" >UploadNumber</Link>
                                                                <div className="dropdown-divider"></div>
                                                               
                                                                <Link className="dropdown-item p-2" to="/AddAgent" >AddAgent</Link>
                                                                <div className="dropdown-divider"></div>

                                                            </div>
                                                        </li> 
                                                       {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/AddLead">
                                                                <i className="fa fa-users mr-15" aria-hidden="true"></i>Leads</Link>
                                                        </li> */}

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Crm">
                                                                <i className="fa fa-database mr-15" aria-hidden="true"></i>CRM</Link>
                                                        </li> */}

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Ivr">
                                                                <i className="fa fa-tree mr-15" aria-hidden="true"></i>IVR</Link>
                                                        </li> */}

                                                         <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Dashboard">
                                                                <i className="fa fa-tree mr-15" aria-hidden="true"></i>SwitchToIVR</Link>
                                                        </li>

                                                        {/* <li className="nav-item dropdown p-2">                                                        
                                                                <i className="fa fa-users mr-15" aria-hidden="true"></i>  
                                                                <Link className="nav-link" to="/AddLead" >Leads</Link>                                               
                                                           
                                                        </li> */}


                                                    </ul>
                                                    <div className="nav-item float-right mx-4 p-2">
                                                    <Link className="nav-link "  to="/Profile">
                                                            {/* <i className="fa fa-lg fa-user mr-15 " aria-hidden="true" ></i> */}
                                                            
                                                            {userinfodata} </Link>

                                                        

                                                    </div>

                                                    <div className="nav-item float-right mx-4 p-2">
                                                   
                                                        <Link className="nav-link bg-danger " onClick={handleLogout} to="">
                                                            <i className="fa fa-lg fa-sign-out mr-15 " aria-hidden="true" ></i>Logout</Link>



                                                    </div>

                                                </div>

                                            </div>
                                        </nav>



                                    </div>


                                </>
                            )
                        }  else if (localStorage.getItem('roles') === 'ROLE_APIOBDUSER') {
                            return (

                                <>
                                    <div>

                                        {/* <div className="sidenav"> */}
                                        <nav className="navbar fixed-top navbar-expand-lg navbar-dark mybg-primary">
                                            <div className="container-fluid">



                                                <Link className="navbar-brand" to="/LiveOBD"><i className="fa fa-home mx-1" aria-hidden="true"></i>Home</Link>
                                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>

                                                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                                         <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/FilterReport">
                                                            <i className="fa fa-table mr-15" aria-hidden="true"></i>Report</Link>
                                                        </li> 
                                                        <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Addcampaign">
                                                            <i className="fa-solid fa-bullhorn mr-15"></i> Campaign</Link>
                                                        </li> 

                                                     
                                                        
                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa fa-upload mr-15" aria-hidden="true"></i> Store
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item  p-2" to="/UploadAudio" >Upload Audio</Link>
                                                                <div className="dropdown-divider"></div>
                                                                <Link className="dropdown-item p-2" to="/UploadNumber" >UploadNumber</Link>
                                                                <div className="dropdown-divider"></div>
                                                               
                                                                <Link className="dropdown-item p-2" to="/AddAgent" >AddAgent</Link>
                                                                <div className="dropdown-divider"></div>

                                                            </div>
                                                        </li> 
                                                       {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/AddLead">
                                                                <i className="fa fa-users mr-15" aria-hidden="true"></i>Leads</Link>
                                                        </li> */}

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Crm">
                                                                <i className="fa fa-database mr-15" aria-hidden="true"></i>CRM</Link>
                                                        </li> */}

                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Ivr">
                                                                <i className="fa fa-tree mr-15" aria-hidden="true"></i>IVR</Link>
                                                        </li> */}

                                                         {/* <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Dashboard">
                                                                <i className="fa fa-tree mr-15" aria-hidden="true"></i>SwitchToIVR</Link>
                                                        </li> */}

                                                        {/* <li className="nav-item dropdown p-2">                                                        
                                                                <i className="fa fa-users mr-15" aria-hidden="true"></i>  
                                                                <Link className="nav-link" to="/AddLead" >Leads</Link>                                               
                                                           
                                                        </li> */}


                                                    </ul>
                                                    <div className="nav-item float-right mx-4 p-2">
                                                    <Link className="nav-link "  to="/Profile">
                                                            {/* <i className="fa fa-lg fa-user mr-15 " aria-hidden="true" ></i> */}
                                                            
                                                            {userinfodata} </Link>

                                                        

                                                    </div>

                                                    <div className="nav-item float-right mx-4 p-2">
                                                   
                                                        <Link className="nav-link bg-danger " onClick={handleLogout} to="/Login">
                                                            <i className="fa fa-lg fa-sign-out mr-15 " aria-hidden="true" ></i>Logout</Link>



                                                    </div>

                                                </div>

                                            </div>
                                        </nav>



                                    </div>


                                </>
                            )
                        }else if (localStorage.getItem('roles') === 'ROLE_ADMIN') {

                            return (

                                <>
                                    <div>

                                        {/* <div className="sidenav"> */}
                                        <nav className="navbar fixed-top navbar-expand-lg navbar-dark mybg-primary">
                                            <div className="container-fluid">



                                                {/* <Link className="navbar-brand" to="/">OBD</Link> */}
                                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>

                                                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                                        <li className="nav-item p-2">
                                                            <Link className="nav-link" aria-current="page" to="/Home">
                                                                <i className="fa fa-home mr-15" aria-hidden="true"></i>Home</Link>
                                                        </li>


                                                        {/* <li className="nav-item p-2">
                                                            <Link className="nav-link " to="/">
                                                                <i className="fa fa-user-o mr-15" aria-hidden="true"></i>User</Link>

                                                        </li> */}

                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa fa-table mr-15" aria-hidden="true"></i> Report
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item p-2" to="/Report">
                                                                    Report</Link>

                                                                <Link className="dropdown-item  p-2" to="/FilterReport" >Filter Report</Link>
                                                                <div className="dropdown-divider"></div>



                                                            </div>


                                                        </li>




                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa fa-user-plus mr-15" aria-hidden="true"></i> User
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item p-2" to="/AddUser">
                                                                    Add User</Link>

                                                                {/* <Link className="dropdown-item  p-2" to="/FilterReport" >View User</Link>
                                                                <div className="dropdown-divider"></div> */}



                                                            </div>


                                                        </li>



                                                        {/* <Link className={`mynavlink ${location.pathname === "/about" ? "active" : ""}`} to="/about">
                                            
                                        <i className="fa fa-info-circle mr-15" aria-hidden="true"></i>About</Link> */}

                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa-solid fa-bullhorn mr-15"></i> Campaign
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item  p-2" to="/AddCampaign" >Add Campaign</Link>
                                                                {/* <div className="dropdown-divider"></div> */}
                                                                {/* <Link className="dropdown-item  p-2" to="/Makegui" >Make GUI</Link> */}
                                                                {/* <Link className="dropdown-item p-2" to="/CallLog" >CallLog</Link>
                                                                <div className="dropdown-divider"></div> */}

                                                            </div>

                                                           
                                                        </li>
                                                        <li className="nav-item dropdown p-2">
                                                            <a  className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa fa-upload mr-15" aria-hidden="true"></i> Store
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item  p-2" to="/UploadAudio" >Upload Audio</Link>
                                                                <div className="dropdown-divider"></div>
                                                                <Link className="dropdown-item p-2" to="/UploadNumber" >UploadNumber</Link>
                                                                <div className="dropdown-divider"></div>
                                                                {/* <Link className="dropdown-item p-2" to="/UploadLead" >UploadLead</Link>
                                                                <div className="dropdown-divider"></div> */}
                                                                <Link className="dropdown-item p-2" to="/AddAgent" >AddAgent</Link>
                                                                <div className="dropdown-divider"></div>

                                                            </div>
                                                        </li>

                                                        <li className="nav-item dropdown p-2">
                                                            <a className="nav-link dropdown-toggle " data-bs-toggle="dropdown">
                                                                <i className="fa-solid fa-gear mr-15" aria-hidden="true"></i> Settings
                                                            </a>
                                                            <div className="dropdown-menu p-2">
                                                                <Link className="dropdown-item p-2" to="/ChannelSetting">
                                                                    Channel Setting</Link>
                                                                    <div className="dropdown-divider"></div>
                                                                    <Link className="dropdown-item p-2" to="/Did">
                                                                    Did Assign</Link>
                                                                    <div className="dropdown-divider"></div>

                                                                {/* <Link className="dropdown-item  p-2" to="/FilterReport" >View User</Link>
                                                                <div className="dropdown-divider"></div> */}



                                                            </div>


                                                        </li>


                                                    </ul>

                                                    <div className="nav-item float-right mx-4 p-2">
                                                    <Link className="nav-link "  to="/Profile">
                                                            {/* <i className="fa fa-lg fa-user mr-15 " aria-hidden="true" ></i> */}
                                                            
                                                            {userinfodata} </Link>

                                                        

                                                    </div>

                                                    <div className="nav-item float-right mx-4 p-2">

                                                        <Link className="nav-link bg-danger " onClick={handleLogout} to="">
                                                            <i className="fa fa-lg fa-sign-out mr-15 " aria-hidden="true" ></i>Logout</Link>



                                                    </div>

                                                </div>

                                            </div>
                                        </nav>



                                    </div>


                                </>
                            )

                        } else {
                            return (
                                <>
                                <div>

                                    {/* <div className="sidenav"> */}
                                    <nav className="navbar fixed-top navbar-expand-lg navbar-dark mybg-primary">
                                        <div className="container-fluid">



                                            <Link className="navbar-brand" to="/Dashboard"><i className="fa fa-home mx-1" aria-hidden="true"></i>Dashboard</Link>
                                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>

                                            <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                             
                                                

                                                <div className="nav-item float-right mx-4 p-2">
                                               
                                                    <Link className="nav-link bg-danger " onClick={handleLogout} to="/login">
                                                        <i className="fa fa-lg fa-sign-out mr-15 " aria-hidden="true" ></i>Logout</Link>



                                                </div>

                                            </div>

                                        </div>
                                    </nav>



                                </div>


                            </>
                            )
                        }
                    })()}







                </div>
            }




        </>
    )
}

export default Navbar
