import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';
import Userivrnavbar from './Userivrnavbar';
import Swal from 'sweetalert2';




const Ivrsetting = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Ivr Settings"
     }, []);

    const el = useRef();

    const [uploadFile, setUploadFile] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const onrefreshfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

      //  const pathb = e.target.files[0];
       // console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);
        console.log("called")

        el.current.value = "";

    }

    const submitaddagent = () =>{



    }

    const clearupload = (e) =>{

       // e.preventDefault();
        setUploadFile(e.target.reset())
       // e.target.reset();
    }

    const uploadaudioonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('file', uploadFile)

        const response = await fetch(process.env.REACT_APP_IP+"/User/IvrAudioUpload", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        //props.showAlert(" Uploaded Successfully ", "success");

        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        getAllPosts()

        el.current.value = "";
        


        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }


    const deleteivrsetting = async (event) => {
        //event.preventDefault();
        console.log("event>>>>>>>>" + event)
        var formdata = new FormData()
        formdata.append('id', event)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteIvrsetting", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        getAllivrsettingPosts()

    }





    // const [postsPerPage] = useState(5);

    
    const [keypress, setKeypress] = useState();
    const [group, setGroup] = useState();
    const [keylength, setKeylength] = useState();
    

    const [keypressaudio, setKeypressaudio] = useState(0);
    const [welcomeaudio, setWelcomeaudio] = useState(0);
    const [did, setDid] = useState();
    const [offset, setOffset] = useState(1);
    const [ivroffset, setivrOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [ivrsettingposts, setIvrsettingposts] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [pageCountivrsetting, setPageCountivrsetting] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllIvrAudio?pageNumber=${offset - 1}&&size=5`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const getAllivrsettingPosts = async () => {
        const resa = await axios.get(process.env.REACT_APP_IP+`/User/Allivrsetting?pageNumber=${ivroffset - 1}&&size=5`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const ivrsettingdata = resa.data;
        const postDataivr = getPostDataivrsetting(ivrsettingdata)
        // Using Hooks to set value
        setIvrsettingposts(postDataivr)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCountivrsetting(Math.ceil(ivrsettingdata.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }
    const onIvrInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setivrOffset(offset);

    }
    



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handleIvrPageClick = (event) => {
        const selectedPage = event.selected;
        setivrOffset(selectedPage + 1)
    };

    // for source no in option

    const optionfordid = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/allDidbyuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('didselectmodel');
        elelist.innerHTML = ""+('<option value="">NA</option>');


        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['did'] + '">' + json[i]['did'] + '</option>';
        }
    }
// for audio in drop down
const optionaudio = async (e) => {
    const resppe = await fetch(process.env.REACT_APP_IP+"/User/RestAllIvrAudioSelect", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const jsona = await resppe.json()
    console.log(jsona.length)

    var keypressaudio = document.getElementById('keypressaudio');
    keypressaudio.innerHTML = ""+('<option value="0">NA</option>');
    for (var l = 0; l < jsona.length; l++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        keypressaudio.innerHTML = keypressaudio.innerHTML +
        '<option  value="' + jsona[l]['id'] + '">' +jsona[l]['id']+' | '+ jsona[l]['audioname'] + '</option>';
    }


    var welcomeaudiovar = document.getElementById('welcomeaudio');

    welcomeaudiovar.innerHTML = "0"+('<option value="">NA</option>');

    for (var l = 0; l < jsona.length; l++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        welcomeaudiovar.innerHTML = welcomeaudiovar.innerHTML +
            '<option  value="' + jsona[l]['id'] + '">' +jsona[l]['id']+' | '+ jsona[l]['audioname'] + '</option>';
    }




}

//group option

const optionforgroup = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('groupselectmodel');
    elelist.innerHTML = ""+('<option value="">NA</option>');

    for (var i = 0; i < json.length; i++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | '+json[i]['groupname'] + '</option>';
    }
}

useEffect(() => {
    optionforgroup()
}, [])


useEffect(() => {
    optionaudio()
}, [])
    

    useEffect(() => {
        optionfordid()
    }, [])

    useEffect(() => {
        getAllPosts()
    }, [offset])

    useEffect(() => {
        getAllivrsettingPosts()
    }, [ivroffset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>
                

                <div className='row mt-1'>
                <motion.div   
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ opacity: 0,duration: 1.5  }}
            //   exit={{scale: 1,animate scale: 0}}

               >

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card mybgcolor p-3'>

                            <div className='card-header text-white'>
                                <h2>Existing Audio</h2>

                                <div className="table-responsive">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>User Id</th>
                                                <th>Audio Name</th>
                                                <th>Approve Status</th>
                                                <th>Play</th>

                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.userid}</td>
                                                        <td>{page.audioname}</td>
                                                        <td>{page.approve}</td>
                                                        <td>
                                                            <ReactAudioPlayer
                                                                src={'http://180.179.210.24:8080/IvrAudioFile/'+page.userid+'/'+page.audioname}

                                                                controls />


                                                        </td>




                                                        <td> <i className="far fa-trash-alt mx-2 btn btn-danger " ></i></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>
                    </motion.div>

                </div>


            </>

        )

    }



    const getPostDataivrsetting = (ivrsettingdata) => {
        return (
            <>
                

                <div className='row mt-1'>
                <motion.div   
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ opacity: 0,duration: 1.5  }}
            //   exit={{scale: 1,animate scale: 0}}

               >

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card mybgcolor p-3'>

                            <div className='card-header text-white'>
                                <h2>Existing IVR</h2>

                                <div className="table-responsive">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>Did</th>
                                                <th>Welcome Clip</th>
                                                <th>Welcome</th>
                                                <th>Keypress File Clip</th>
                                                <th>KeypressFile</th>
                                                <th>Keypress Length</th>
                                                <th>Key</th>
                                                <th>Group</th>
                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ivrsettingdata.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.did}</td>
                                                        <td>
                                                            <ReactAudioPlayer
                                                                src={'http://180.179.210.24:8080/IvrAudioFile/'+page.userid+'/'+page.welcomeaudio}

                                                                controls />


                                                        </td>
                                                        <td>{page.welcomeaudio}</td>
                                                        <td>
                                                            <ReactAudioPlayer
                                                                src={'http://180.179.210.24:8080/IvrAudioFile/'+page.userid+'/'+page.keypressaudio}

                                                                controls />


                                                        </td>
                                                        <td>{page.keypressaudio}</td>
                                                        <td>{page.keylength}</td>
                                                        <td>{page.inputkey}</td>
                                                        <td>{page.groupname}</td>


                                                        <td> <i className="far fa-trash-alt mx-2 btn btn-danger " onClick={() => deleteivrsetting(page.id)} ></i></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onIvrInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>
                    </motion.div>

                </div>


            </>

        )

    }



   
    
    const submitivrsetting = async(e) => {
        e.preventDefault();
        console.log(">>>>>>>did>>>>>>>>"+did)
        console.log(">>>>>>>>welcomeaudio>>>>>>>"+welcomeaudio)
        console.log(">>>>>>>>keypressaudio>>>>>>>"+keypressaudio)
        console.log(">>>>>>>>>keylength>>>>>>"+keylength)
        console.log(">>>>>>>>>group>>>>>>"+group)
        console.log(">>>>>>>>>keypress>>>>>>"+keypress)
        const formData = new FormData()
        formData.append('did', did)
        formData.append('welcomeaudioid', welcomeaudio)
        formData.append('keypressaudioid', keypressaudio)
        formData.append('keylength', keylength)
        formData.append('keypress', keypress)
        formData.append('groupid', group)

        const response = await fetch(process.env.REACT_APP_IP+"/User/AddIvrsetting", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        //props.showAlert(" Uploaded Successfully ", "success");

        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 4500
            })
        }

       getAllivrsettingPosts()
     
    }
    
 

    return (


           <>
           
        <Userivrnavbar />

        <div className='row mt-4 mx-1'>

            <div className='col-md-3 col-12 text-white'>
                <div className='mt-5'>
                     
                    <form className=" text-black" onSubmit={submitivrsetting}>
                        <div className='card p-3 mybgcolor'>
                            <h2 className='card-header bg-primary text-center text-white'>
                                     Draw IVR
                            </h2>
                        

                            <div className=" mb-3 mt-2 ">
                                <i className="fa fa-link mx-1 mb-2"></i><b htmlFor="groupname">Source Number:</b>
                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                <select id="didselectmodel" className="form-select" onChange={(e) => setDid(e.target.value)}
                                    required>
                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                            <option value=""></option> */}


                                </select>

                            </div>

                            <div className=" mb-3 ">
                                <i className="fa fa-link mx-1 mb-2"></i><b htmlFor="groupname">Welcome Audio:</b>
                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                <select id="welcomeaudio" className="form-select" onChange={(e) => setWelcomeaudio(e.target.value)}
                                    >
                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                            <option value=""></option> */}


                                </select>

                            </div>
                            <div className=" mb-3 ">
                                <i className="fa fa-link mx-1 mb-2"></i><b htmlFor="groupname">Keypress Audio:</b>
                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                <select id="keypressaudio" className="form-select" onChange={(e) => setKeypressaudio(e.target.value)}
                                    >
                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                            <option value=""></option> */}


                                </select>

                            </div>
                            <div className=" mb-3 ">
                                <i className="fa fa-link mx-1 mb-2"></i><b htmlFor="groupname">Keypress Length:</b>
                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                <select id="keylength" className="form-select" onChange={(e) => setKeylength(e.target.value)}
                                    required>
                                        <option value="">NA</option>
                                     <option value="1">1</option>
                                     <option value="2">2</option> 
                                     <option value="3">3</option> 
                                     <option value="4">4</option> 
                                     <option value="5">5</option>
                                     <option value="6">6</option> 
                                     <option value="7">7</option> 
                                     <option value="8">8</option> 
                                     <option value="9">9</option> 

                                </select>

                            </div>
                            <div className=" mb-3 ">
                                <i className="fa fa-link mx-1 mb-2"></i><b htmlFor="keypress">Keypress :</b>
                                <input type="text" className='form-control' onChange={(e) => setKeypress(e.target.value)} required={true} />

                            </div>
                            <div className=" mb-3 ">
                                <i className="fa fa-link mx-1 mb-2"></i><b htmlFor="groupname">Select Group:</b>
                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                <select id="groupselectmodel" className="form-select" onChange={(e) => setGroup(e.target.value)}
                                    required>
                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                            <option value=""></option> */}


                                </select>

                            </div>
                            <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                        </div>


                            </div>

                                    
                            </form>
                </div>


            </div>
           

            <div className='col-md-9 col-12 text-white mt-5'>

            {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
            <div className='col-md-12 col-12 text-white'>
                 

                <form className='card p-3 mybgcolor' onSubmit={uploadaudioonsubmit}>
                    <div className='row'>
                                <div className='col-md-4'>
                                    <h4>Upload Audio</h4>
                                </div>

                                <div className='col-md-8'>
                                    <div className=" mt-1">

                                        <input className=" form-control form-control-sm" ref={el}
                                            id="formFileSm" name="file" type="file" onChange={onchangfun} required />
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-md-4'></div>
                                    <div className='col-md-8'>
                                        <input className="btn btn-primary mt-3 mx-2" type="reset" value="Refresh" onClick={onrefreshfun} />
                                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                    </div>
                                    
                                </div>   
                    

                    </div>
                </form>

            </div>


            <div className="col-md-12 col-12">

                {/* Display all the posts */}
                {posts}

                <div className="row">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12 mb-5 text-black">


                        <ReactPaginate
                           previousLabel={<i className="fa-solid fa-angles-left text-black"></i>}
                           nextLabel={<i className="fa-solid fa-angles-right text-black"></i>}
                            breakLabel={".."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>

            </div>



           


        </div>
        <div className="col-md-12 col-12">

{/* Display all the posts */}
{ivrsettingposts}

<div className="row">
    <div className="col-md-3 col-12"></div>

    <div className="col-md-6 col-12 mb-5 text-black">


        <ReactPaginate
            previousLabel={<i className="fa-solid fa-angles-left text-black"></i>}
            nextLabel={<i className="fa-solid fa-angles-right text-black"></i>}
            breakLabel={".."}
            breakClassName={"break-me"}
            breakLinkClassName={"break-mef"}
            pageCount={pageCountivrsetting}
            onPageChange={handleIvrPageClick}
            forcePage={offset - 1}

            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}

        />


    </div>

</div>



</div>




        </>
    )


}


export default Ivrsetting
