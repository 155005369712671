import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate,Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { useLocation } from "react-router"
import { CSVLink, CSVDownload } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';
import Userivrnavbar from './Userivrnavbar';




const LeadCalllog = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

   
    const location = useLocation();
    const state = location.state;
    console.log(">>>>>id>>>"+state.id);
   // const [calllogid,setCalllogid] =  useState();
   // setCalllogid(state.id);
    
    const [email, setEmail] = useState();
    const [agentnumber, setAgentnumber] = useState();
    const [agentname, setAgentname] = useState();

    

    const addagentonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        //formData.append('file', uploadFile)
        formData.append('email', email)
        formData.append('name', agentname)
        formData.append('number', agentnumber)

        
        

        const response = await fetch(process.env.REACT_APP_IP+"/User/AddLeaddata", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        if(json.type === "OK")
        {
         props.showAlert("Added  successfully", "success");
 
        }else{
 
         props.showAlert("!!!"+json.msg, "danger");
        }
        getAllPosts()

        



        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }



   





    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/AllLeadslogdata?pageNumber=${offset - 1}&&size=10&&leadsid=${state.id}`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }

    function callfunction(data){

        alert("called>>>>>"+data)

        

    }


    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const clicktocallMethod = () =>{

        alert("Called")
    }

    useEffect(() => {
        getAllPosts()
    }, [offset])

    const data2 = {
        from: "Link #2",
        message: "Just another message",
        timestamp: Date.now(),
      };



    const getPostData = (data) => {
        return (
            <>

                <div className='row mt-1 '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12' >

                        <div className='card  mt-5' style={{
                            backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)"
                            
                        }}>

                            <div className='card-header text-white'>
                                <h2>Existing Lead</h2>

                                <div className="table-responsive mt-3">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Number</th>
                                                <th>CallLog</th>
                                                <th>Date</th>

                                                <th>Email</th>
                                                <th>Remarks</th>
                                                <th>Status</th>
                                                <th>Interested</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.custname}</td>
                                                        <td>{page.custnumber}<i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}}onClick={() => clicktocallMethod(page.id)} ></i></td>
                                                        
                                                        {/* <td><i className="fa fa-info-circle  btn btn-primary " onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                                                       <td> </td>

                                                        <td>{page.leadinsertdate}</td>

                                                        <td>{page.email}</td>
                                                        
                                                        <td> {page.remarks}</td>
                                                        <td> {page.status}</td>
                                                        <td> {page.interested}</td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }


    return (

<>
<Userivrnavbar />



        <div className='row mt-4 mx-1'>





            <div className="col-md-12 col-12" >

                {/* Display all the posts */}
                {posts}

                <div className="row">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12 mb-5">


                        <ReactPaginate
                            previousLabel={<i className="fa-solid fa-angles-left"></i>}
                            nextLabel={<i className="fa-solid fa-angles-right"></i>}
                            breakLabel={".."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>




        </div>
        </>
    )


}


export default LeadCalllog
